import { Component, OnInit } from '@angular/core';
// plugin
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, of, Subject, concat } from 'rxjs';
import {
  merge,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  map,
  startWith,
} from 'rxjs/operators';

// constants
import { callAPIConstants } from 'src/app/common/constants/callAPI-constants';
// services
import { ShowErrorService } from 'src/app/common/service/show-error.service';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';
import { CommonService } from 'src/app/common/service/common.service';
import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/common/constants/routerLink-constants';

@Component({
  selector: 'app-duplicate-questionnaire',
  templateUrl: './duplicate-questionnaire.component.html',
  styleUrls: ['./duplicate-questionnaire.component.scss'],
})
export class DuplicateQuestionnaireComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  submitted: boolean = false;
  //observable assign
  public domainList: Observable<any>;
  public domainList1: Observable<any>;
  public internalAssigneDataSource = new Subject<any>();
  public internalAssigneLoader: boolean = false;
  public focus$ = new Subject<string>();
  taglist = [
    { name: 'Active', value: true },
    { name: 'InActive', value: false },
  ];
  questionnaireData: any = {};
  buttonName: any = 'Add';
  devChecklistId: any;
  modalData: any;
  notFoundText1: any;
  public types = [
    {
      generalType: 'Blood Product',
      specificTypes: [
        'Blood Product Administration Issue',
        'Blood Product Delivery Issue',
        'Blood Product Wasted',
        'Ordering Issue',
        'Other (please specify)',
        'Transfusion Laboratory Process Issue',
        'Transfusion Reaction',
      ],
    },
    {
      generalType: 'Defective Product/ Recall Notification',
      specificTypes: [
        'Disposable/Single Use Product Issue',
        'Other (please specify)',
        'Reprocessable Product Issue',
      ],
    },
    {
      generalType: 'Diagnosis/Treatment',
      specificTypes: [
        'Allergic Reaction (Non Medication-related)',
        'Delay in Treatment/Results',
        'Delay/Lack of Response to Patient Condition (e.g. not assessed/deterioration not recognized)',
        'Diagnosis - Delayed',
        'Diagnosis Issue',
        'Other (please specify)',
        'Treatment - Inappropriate',
        'Treatment - Incorrectly Performed',
        'Unexpected Patient Cardiopulmonary and/or Respiratory Arrest',
        'Workplace Incident',
        'Wrong Patient',
      ],
    },
    {
      generalType: 'Emergency Code Call',
      specificTypes: [
        'Adult Support (Illness/Injury in adult)',
        'Code Blue (Life Threatening Medical Event)',
        'Code Brown (Hazardous spill/decontamination)',
        'Code ED (High volume/acuity in ED)',
        'Code Green (Evacuation)',
        'Code Grey (IS/Facilities failure/loss)',
        'Code Orange (Disaster/Mass Casualty Incident)',
        'Code Pink (Neonatal Resuscitation)',
        'Code Red (Fire)',
        'Code Transfusion (8888)',
        'Code White (Behavior with risk of harm)',
        'Code Yellow (Missing/abducted pt/child)',
        'Lockdown',
        'Penetrating Trauma',
        'Shelter in place',
        'SPOT (Child/pt worsening condition)',
        'STAT',
        'Trauma',
      ],
    },
    {
      generalType: 'Equipment/Medical Device',
      specificTypes: [
        'Broken Item',
        'Disconnected',
        'Disposable/Single Use Product Issue',
        'Electrical Issue',
        'Equipment - Faulty',
        'Equipment - Not Available',
        'Equipment Malfunction',
        'Reprocessable Product Issue',
      ],
    },
    {
      generalType: 'Fall/Injury',
      specificTypes: [
        'Fall',
        'Injury',
        'Other (please specify)',
        'Workplace Incident',
      ],
    },
    {
      generalType: 'High Risk Patient Behaviour',
      specificTypes: [
        'Assault',
        'AWOL/Missing',
        'Behavioural Issue',
        'Contraband item(s) found',
        'Other (please specify)',
        'Restraint Related',
        'Restraint Related Issue',
        'Self Harm',
        'Suicidal Gesture/Attempt',
        'Workplace Hazard',
        'Workplace Incident',
      ],
    },
    {
      generalType: 'Infection',
      specificTypes: [
        'Additional Precautions Issue',
        'Hospital Acquired Infection/Colonization',
        'Other (please specify)',
        'Patient Exposure - Blood/Body Fluid',
        'Patient Exposure - Other',
        'Routine Practices Issue',
        'Sterilization/Disinfection/Reprocessing Issue',
        'Workplace Hazard',
      ],
    },
    {
      generalType: 'IV/Vascular Access Device',
      specificTypes: [
        'Catheter or Tubing Cracked/Damaged/Severed',
        'CVL Maintenance Bundle Issue',
        'Disconnected',
        'Dislodged/Displaced',
        'Dislodgement/Displaced',
        'Infiltration/Extravasation',
        'Insertion',
        'Multiple Attempts/Unnecessary Blood Draw/Attempts',
        'Occlusion',
        'Other (please specify)',
        'Policy/Procedure Not Followed',
        'Policy/Procedure Not Followed (CVL Maintenance Bundle Issue)',
        'Routine Practices Issue',
        'Tubing Not Changed',
        'Wrong Lumen Accessed',
      ],
    },
    {
      generalType: 'Lab/Specimen',
      specificTypes: [
        'Collection Issue',
        'Critical Value Issue',
        'Handling/Storage Issue',
        'Labeling/ID Issue',
        'Labelling Signature Issue',
        'Laboratory Equipment/Instrument Issue',
        'Laboratory Supply Issue',
        'Ordering/Requisition Issue',
        'Other (please specify)',
        'Result Delayed',
        'Result Incorrect',
        'Result Not Reported',
        'Result Reported to Wrong Patient',
        'Specimen Accessioning Issue',
        'Specimen Receiving Issue',
        'Specimen Transport Issue',
      ],
    },
    {
      generalType: 'Line/Tube',
      specificTypes: [
        'Blocked/Occluded',
        'Broken/Perforated/Severed',
        'Care Protocol Not Followed (e.g. not changed)',
        'Disconnected',
        'Discontinued Without Order',
        'Dislodged/Displaced',
        'Dislodgement/Displaced',
        'Insertion',
        'Other (please specify)',
        'Policy/Procedure Not Followed',
        'Wrong Insertion Location',
        'Wrong Size Inserted',
        'Wrong Tube Type Inserted',
      ],
    },
    {
      generalType: 'Medical Imaging',
      specificTypes: [
        'Contrast - Allergic Reaction',
        'Contrast - Event',
        'Contrast - Extravasation',
        'Delay in Treatment/Results',
        'Exposure - Overexposure',
        'Image - Destroyed/Lost',
        'Image - Mislabeled/Incorrect',
        'MRI Safety Issue',
        'Ordering/Requisition Issue',
        'Other (please specify)',
        'Policy/Procedure Not Followed',
        'Results - Incorrect Reading',
        'Test - Ordered, Not Performed',
        'Test - Wrong Procedure',
        'Test - Wrong Side (L vs R)',
        'Test - Wrong Site',
        'Wrong Patient',
      ],
    },
    {
      generalType: 'Medication/IV Solution',
      specificTypes: [
        'Adverse Drug Reaction',
        'Barcode Issue',
        'Contraindicated - Known Allergy',
        'Contraindication-Known Allergy/Intolerance',
        'Delivered to wrong unit',
        'Drug incompatibility',
        'Duplicate Orders',
        'Expired Product',
        'Extra dose',
        'Late dose (>1hr)',
        'Late dose/delay in Treatment',
        'Medication Reconciliation',
        'Medication Reconciliation Issue',
        'Missed dose',
        'Missing Drug (non-narcotic)',
        'Narcotic Discrepancy',
        'Ordering Issue',
        'Other (please specify)',
        'Pain Management Inadequate',
        'Product damaged',
        'Product integrity',
        'Product integrity issue',
        'Pump Programming',
        'Pump Programming Issue',
        'Storage Incorrect/Improper Disposal',
        'Therapeutic Drug Level Monitoring',
        'Therapeutic Drug Level Monitoring Issue',
        'Wrong Concentration',
        'Wrong Dose',
        'Wrong Drug',
        'Wrong Formulation',
        'Wrong Patient',
        'Wrong Rate',
        'Wrong Route',
        'Wrong Site',
        'Wrong Solution',
      ],
    },
    {
      generalType: 'Morbidity/Mortality Review',
      specificTypes: ['Morbidity'],
    },
    {
      generalType: 'Nutrition',
      specificTypes: [
        'Allergic Reaction (Non Medication-related)',
        'Allergy Alert Not Followed / Documented',
        'Delayed Nutrition',
        'Feeding Protocol / Order Not Followed',
        'Incorrect / Missing Portion',
        'Labeling/ID Issue',
        'NPO Guidelines Not Followed',
        'Ordering Issue',
        'Other (please specify)',
        'Spoiled/Expired Product',
        'Wrong Nutritional Product',
        'Wrong Patient',
        'Wrong Rate / Amount',
      ],
    },
    {
      generalType: 'Patient ID/Documentation/Consent',
      specificTypes: [
        'Chart Correction',
        'Consent Issue',
        'Delay/Lack of Response to Patient Condition (e.g. not assessed/deterioration not recognized)',
        'Image - Mislabeled/Incorrect',
        'Lost/Missing/Incomplete Documentation',
        'Other (please specify)',
        'Patient Identification Issue',
        'Patient Registration',
        'Patient Registration Issue',
        'Workplace Hazard',
        'Workplace Incident',
        'Wrong Patient',
      ],
    },
    {
      generalType: 'Privacy',
      specificTypes: [
        'Access/View Without Authority',
        'Disclosure of PHI Without Consent',
        'Discussed/Displayed/Distributed Information Inappropriately',
        'Improper Destruction',
        'Incorrect Patient Information',
        'Lost/Stolen/Missing Information',
        'Ordering/Requisition Issue',
        'Other (please specify)',
        'Patient Identification Issue',
        'Patient Registration Issue',
        'Policy/Procedure Not Followed',
        'Scanned/Imported to Incorrect Chart',
        'Unprotected/Unguarded Patient Information',
        'Workplace Incident',
      ],
    },
    {
      generalType: 'Provision of Care',
      specificTypes: [
        'Biomed Equipment',
        'Biomed Equipment issue',
        'Delay/Lack of Response to Patient Condition (e.g. not assessed/deterioration not recognized)',
        'Equipment Not Available',
        'Failure to Obtain Appropriate Assistance',
        'Failure to Respond to Request for Service',
        'Home Care patient not seen not found',
        'Inappropriate Discharge',
        'Information Transfer (i.e. handover)',
        'Left Against Medical Advice',
        'Order Completion Delay/Not Done',
        'Other (please specify)',
        'Policy/Procedure Not Followed',
        'Protocol Deviations for Research Protocols',
        'Referral',
        'Referral Issue',
        'Simulation',
        'SPOT (Child/pt worsening condition)',
        'Transport/Transfer',
        'Transport/Transfer Issue',
        'Unanticipated transfer to higher level of care',
        'Unexpected Patient Cardiopulmonary and/or Respiratory Arrest',
        'Unexpected Patient Death',
        'Visitor Policy Issue',
        'Workplace Hazard',
      ],
    },
    {
      generalType: 'Respiratory / Airway Management',
      specificTypes: [
        'Airway Obstructed',
        'Decannulation - unplanned',
        'Extubation - Unplanned',
        'Intubation - Delayed',
        'Intubation - Difficult',
        'Intubation - Failed',
        'Medical Gas Malfunction',
        'Medical Gas- rate/flow/concentration incorrect',
        'Other (please specify)',
        'Respiratory Mgmt - Delayed',
        'Respiratory Mgmt - Inappropriate',
        'Ventilation Issue',
        'Wrong Patient',
      ],
    },
    {
      generalType: 'Skin/Tissue',
      specificTypes: [
        'Dehiscence/Evisceration',
        'Injury',
        'Other (please specify)',
        'Pressure Injury',
        'Skin Integrity Problems',
      ],
    },
    {
      generalType: 'Surgery/Procedure',
      specificTypes: [
        'Break in Sterile Technique',
        'Burn',
        'Complications of Anesthesia',
        'Consent Issue',
        'Count Issue',
        'Diagnosis - Delayed',
        'Foreign Object Retained Post Procedure',
        'Implant Issue',
        'Instrumentation/Equipment Issue',
        'Laboratory Equipment/Instrument Issue',
        'Other (please specify)',
        'Patient Identification Issue',
        'Procedure Issue',
        'Specimen Handling/Storage Issue',
        'Sterilization/Disinfection/Reprocessing Issue',
        'Surgical Site Not Marked',
        'Tissue Trauma From Procedure',
        'Wrong Patient',
      ],
    },
    {
      generalType: 'Workplace Hazard/Incident',
      specificTypes: [
        'Behavioural Issue',
        'Other (please specify)',
        'Workplace Hazard',
        'Workplace Incident',
      ],
    },
  ];

  public questionnaires = [];

  public specificTypes = [];
  constructor(
    public showErrorService: ShowErrorService,
    public bsModalRef: BsModalRef,
    private router: Router,
    public commonService: CommonService,
    public broadCasterService: BroadcasterService,
    public localStorageService: LocalStorageService
  ) {
    console.log('first');
    this.getQuestionnaireList();
  }
  public flag: boolean = false;
  ParentRegionData: any;
  ParentRegionDataAPi: any;
  ngOnInit(): void {}
  onGeneralType(event) {
    this.specificTypes = event.specificTypes;

    console.log(this.specificTypes);
  }
  getQuestionnaireList() {
    this.commonService
      .callApi(this.callAPIConstants.AllQuestionnaireList, {}, 'post')
      .then((success) => {
        if (success.status == 1) {
          console.log(success);
          this.questionnaires = success.listing;
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }
  /****************************************************************************
  @Purpose     : Add/Update Project
  @Parameter   : form, projectData
  @Return      : NA
  /****************************************************************************/

  createUpdate(form, Data) {
    if (form.valid) {
      this.commonService
        .callApi(
          this.callAPIConstants.Duplicatequestionnaire,
          form.value,
          'post'
        )
        .then((success) => {
          if (success.status == 1) {
            this.broadCasterService.broadcast('listingAddUpdate', true);
            this.showErrorService.popToast('success', success.message);
          } else {
            this.showErrorService.popToast('error', success.message);
            this.router.navigate([this.URLConstants.QUESTION]);
          }
        });
      this.bsModalRef.hide();
    } else {
      this.submitted = true;
    }
  }
}
