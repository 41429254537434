<style>
  .download-btn {
    position: relative;
  }

  .download-dropdown-menu {
    min-width: 777px !important;
    border: none !important;
    border-radius: 5px;
    padding: 20px !important;
    width: 500px;
  }

  .permissions {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }

  .custom-checkbox-import {
    display: flex;
    align-items: center;
    width: 100px;
    margin-right: 15px;
    margin-bottom: 10px;
  }

  .custom-checkbox-import label {
    display: flex;
    align-items: center;
  }

  .custom-checkbox-import input {
    margin-right: 10px;
  }

  .file-drop-area {
    text-align: center;
    border: 0.3px solid #3a86ff;
    border-radius: 5px;
    padding: 30px;
    margin: 30px 50px;
    background-color: #edf2f6;
    .btn {
      border: 1px solid #2e6bcc;
      background-color: #f6f9ff;
      color: #225099;
    }
    p {
      color: #3a86ff;
    }
  }

  .drag-drop-zone {
    cursor: pointer;
  }

  .cancel-btn {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    color: #3a86ff;
    margin-bottom: 1rem;
    font-weight: 400;
  }
  .subheadtitle {
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    text-align: left;
  }
  .btn-primary {
    height: fit-content;
  }
  .action-ico {
    padding: 5px;
  }
  .custom-dropdown {
    margin: 0;
  }
  .color-squre {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 8px;
    border: 0;
    cursor: pointer;
  }

  .color-squre-mini {
    height: 10px;
    width: 10px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 8px;
    border: 0;
    cursor: pointer;
  }

  .color-primary {
    position: relative;
  }

  .color-primary .color-squre {
    background: #5a4fc4;
  }

  .color-secound .color-squre {
    background: #f19a64;
  }

  .example-list {
    width: 500px;
    max-width: 100%;
    border: solid 1px #ccc;
    min-height: 60px;
    display: block;
    background: white;
    border-radius: 4px;
    overflow: hidden;
  }

  .example-box {
    padding: 20px 10px;
    border-bottom: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-size: 14px;
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .cdk-drag-placeholder {
    opacity: 0;
  }

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .example-box:last-child {
    border: none;
  }

  .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .download-dropdown .download-dropdown-menu {
    width: 100%;
    color: var(--dt-text-color);
    top: 3rem !important;
    position: absolute;
    right: 0;
    z-index: 1000;
    float: left;
    min-width: 660px;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    animation: 0.8s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
    border-radius: 5px;
    box-shadow: -7px 8px 16px 0px rgba(55, 70, 95, 0.2);
    background: var(--input-bg);
    display: none;
  }

  .left-col {
    flex: 0 0 20%;
    min-width: 175px;
    height: 350px;
    overflow-y: auto;
  }

  .left-col .list-group-item {
    border: none;
    border-radius: 0;
    align-items: center;
    padding: 15px 10px 15px 20px;
    background: var(--input-bg);
  }

  .left-col .list-group-item .handle {
    margin-right: 10px;
  }

  .addIcon {
    outline: none;
  }

  .left-col .list-group-item .handle .custom-checkbox label {
    margin: 0;
    font-size: 0.875rem !important;
    line-height: 20px !important;
    padding-left: 38px;
    color: var(--input-color) !important;
    font-weight: 600 !important;
  }

  .right-col .col-left {
    flex: 0 0 65%;
    margin-right: 1rem;
  }

  .right-col .col-right {
    flex: 0 0 auto;
  }

  .right-col .col-right .right-col-list {
    max-height: 200px;
    overflow: auto;
  }

  .template-list-outer {
    height: 220px;
    overflow-y: auto;
  }

  .template-list-outer .template-list .list-group-item {
    padding-top: 9px;
    padding-bottom: 9px;
    border: 0;
  }

  .download-dropdown.open .download-dropdown-menu {
    display: block;
  }

  .bottom-buttons button {
    min-width: 90px;
    color: var(--text-color);
    border-color: var(--border-color);
    background: var(--bg-white);
  }

  .list-group-item {
    border: none;
    border-radius: 0;
    align-items: center;
    padding: 15px 10px;
    background: var(--input-bg);
  }

  .list-group-item label {
    margin: 0;
    font-size: 0.875rem !important;
    line-height: 20px !important;
    padding-left: 38px;
    color: var(--input-color) !important;
  }

  .filter-form {
    position: relative;
  }

  .field-form {
    width: calc(100% - 56px);
  }

  .filter-form .row-add {
    position: absolute;
    right: 10px;
    bottom: 16px;
  }

  .save-filter {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  .caption-image {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
  }

  .caption-image img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  table.dataTable.dtr-inline.collapsed
    > tbody
    > tr[role='row']
    > td:first-child:before,
  table.dataTable.dtr-inline.collapsed
    > tbody
    > tr[role='row']
    > th:first-child:before {
    top: 21px;
  }

  table.dataTable.dtr-inline.collapsed
    > tbody
    > tr[role='row']
    > td:first-child,
  table.dataTable.dtr-inline.collapsed
    > tbody
    > tr[role='row']
    > th:first-child {
    position: absolute;
    padding-left: 30px;
    cursor: pointer;
  }

  table.dataTable.dtr-inline.collapsed
    > tbody
    > tr[role='row']
    > td:first-child:before {
    top: 9px;
  }

  table.dataTable tbody tr {
    background: var(--bg-white);
  }

  table.dataTable.no-footer {
    border-bottom: none !important;
  }

  .error-block {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #ff5b5c;
  }

  @media screen and (max-width: 991px) {
    .btn .minW-md-0 {
      min-width: unset;
    }
  }

  table.dataTable thead th {
    padding: 1rem !important;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  table.dataTable thead th:first-child {
    padding-left: 30px !important;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  table.dataTable thead th:nth-child(2) {
    min-width: 100px;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  table.dataTable tbody td {
    padding: 0.5rem 1rem !important;
    font-family: 'IBM Plex Sans', sans-serif;
  }

  table.dataTable tbody td:first-child {
    padding-left: 30px !important;
    font-family: 'IBM Plex Sans', sans-serif;
  }
  .bs-datepicker {
    background: var(--input-bg) !important;
  }
  .bs-datepicker-body table td {
    color: var(--text-color);
  }
  .limitTextHeight {
    height: 20px;
    overflow: hidden;
  }
  .container {
    font-size: 16px;
    line-height: 16px;
    height: 32px;
    overflow: hidden;
  }

  .show {
    overflow: visible;
    height: auto;
  }
  .btncolor {
    color: #5a4fc4;
  }
  .btncolorR {
    color: #d42828;
  }
</style>
<!-- BEGIN : Show Skeleton Loader -->
<div class="content-wrapper" [hidden]="!showSkeletonLoader1">
  <div class="card animated fadeIn">
    <div class="card-header">
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="card-body">
      <ngx-skeleton-loader count="5"></ngx-skeleton-loader>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <ngx-skeleton-loader count="1"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END : Show Skeleton Loader -->

<div class="content-wrapper" [hidden]="showSkeletonLoader1">
  <div
    class="card animated fadeIn"
    style="padding-top: 0; padding-left: 20px; padding-right: 20px"
  >
    <!-- TABLE HEADER START-->
    <div *ngIf="this.conditions.showTableHeader" class="card-collapse">
      <div class="row data-filter justify-content-end">
        <div class="col-md-4">
          <div class="button-continer text-left">
            <div
              class="btn-group"
              dropdown
              #dropdown="bs-dropdown"
              [insideClick]="true"
            ></div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6 text-md-right">
          <div class="button-continer text-right">
            <div
              class="btn-group"
              dropdown
              #dropdown="bs-dropdown"
              [insideClick]="true"
            >
              <div>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                  <div
                    class="dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                    style="width: 650px"
                  >
                    <div class="card">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div
                              *ngFor="let i of tableConfig?.cols"
                              [ngClass]="i.columnVisibility ? 'col-md-12' : ''"
                            >
                              <ng-container *ngIf="i.columnVisibility">
                                <div class="custom-checkbox">
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="status"
                                      (change)="
                                        selectColumns(tableConfig?.cols)
                                      "
                                      [(ngModel)]="i.isSelected"
                                    />
                                    <span></span>
                                    {{ i.colName }}
                                  </label>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Save Template"
                                [(ngModel)]="options.templateName"
                                #templateName="ngModel"
                                [disabled]="!count"
                              />
                            </div>

                            <button
                              class="btn btn-primary"
                              [disabled]="!count"
                              (click)="saveDownloadTemplate(tableConfig?.cols)"
                            >
                              Save Template
                            </button>
                            <span
                              [style.background]="color"
                              [cpPosition]="'bottom-left'"
                              class="color-squre mb-2"
                              [(colorPicker)]="color"
                              [cpOKButton]="true"
                              [cpSaveClickOutside]="false"
                              [cpOKButtonClass]="'btn btn-primary btn-md'"
                            ></span>
                            <div class="row">
                              <div
                                *ngFor="let i of downloadTemplatesArr"
                                class="save-filter col-md-12 mt-2"
                              >
                                <span
                                  [style.background]="i.color"
                                  class="color-squre-mini"
                                  [(colorPicker)]="i.color"
                                  [disabled]="true"
                                  [cpOKButtonClass]="'btn btn-primary btn-md'"
                                ></span>
                                <span class="ml-2" (click)="showTemplate(i)"
                                  >{{ i.description }}
                                </span>
                                <span
                                  class="fa fa-close ml-2"
                                  aria-hidden="true"
                                  tooltip="Delete"
                                  placement="bottom"
                                  (click)="CallAPIdeleteDownloadTemplate(i)"
                                ></span>
                                <span
                                  class="fa fa-eye ml-2"
                                  aria-hidden="true"
                                  tooltip="View"
                                  placement="bottom"
                                  (click)="showTemplate(i)"
                                ></span>
                                <span
                                  class="fa fa-check ml-2"
                                  aria-hidden="true"
                                  tooltip="Edit"
                                  placement="bottom"
                                  (click)="editTemplate(i)"
                                ></span>
                              </div>
                              <div *ngIf="!downloadTemplatesArr?.length">
                                No Templates Found
                              </div>
                            </div>
                            <div class="row mt-2">
                              <button
                                type="button"
                                class="nav-link btn btn-outline-primary ml-2 col-md-2"
                                (click)="downloadTemplate('pdf')"
                                [disabled]="!count"
                              >
                                Pdf
                              </button>
                              <button
                                type="button"
                                class="nav-link btn btn-outline-primary ml-2 col-md-2"
                                (click)="downloadTemplate('excel')"
                                [disabled]="!count"
                              >
                                Excel
                              </button>
                              <button
                                type="button"
                                class="nav-link btn btn-outline-primary ml-2 col-md-2"
                                (click)="downloadTemplate('csv')"
                                [disabled]="!count"
                              >
                                CSV
                              </button>
                              <button
                                type="button"
                                class="nav-link btn btn-outline-primary ml-2 col-md-2"
                                (click)="downloadTemplate('print')"
                                [disabled]="!count"
                              >
                                Print
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <!-- download CSV and EXCEL -->

            <ng-container *ngIf="this.conditions.showExport">
              <div class="dropdown">
                <button
                  class="nav-link btn btn-teal dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <!-- <span>Import</span> -->
                </button>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a class="dropdown-item" (click)="importCSVandExcel('csv')">
                    CSV File</a
                  >
                  <a class="dropdown-item" (click)="importCSVandExcel('excel')">
                    Excel File
                  </a>
                </div>
              </div>
            </ng-container>

            <!-- download CSV and EXCEL -->
          </div>
        </div>
      </div>
    </div>
    <!-- ON COLLAPSE COLUMN HIDE SHOW -->
    <div class="card-body">
      <!-- Data Table Start -->
      <!-- filter/download/search buttons -->
      <div class="user-listing-filterOptions d-block">
        <div class="row">
          <div class="col-lg-4 position-static">
            <div class="left-buttons d-flex">
              <span class="subheadtitle">{{ subHeading }}</span>
              <!-- <label *ngIf="this.conditions.search" class="mb-0">
                <div class="search-input-wrapper position-relative">
                  <div class="form-group position-relative">
                    <em class="bx bx-search"></em>
                    <input
                      type="text"
                      class="form-control icon-search"
                      placeholder="search"
                      name="search"
                      #search="ngModel"
                      [(ngModel)]="searchText"
                      autocomplete="off"
                      (keyup.enter)="searchData($event)"
                    />
                    <div *ngIf="this.type != 'reachlist'" class="remove-icon">
                      <em
                        class="bx bx-x"
                        *ngIf="searchText"
                        (click)="removeSearch()"
                      ></em>
                    </div>
                    <div *ngIf="this.type === 'reachlist'" class="remove-icon">
                      <em
                        class="bx bx-x"
                        *ngIf="searchText"
                        (click)="removeSearch1()"
                      ></em>
                    </div>
                  </div>
                </div>
              </label> -->

              <!-- <div
                *ngIf="this.conditions.showFilter"
                class="custom-dropdown filter-data-dropdown position-static ml-2"
                [ngClass]="status ? 'open' : ''"
              >
                <button
                  (click)="openFilter()"
                  class="btn btn-default dropdown-toggle minW-md-0 btn-bg-white"
                  type="button"
                >
                  <i class="bx bx-filter d-lg-none"></i>
                  <span class="d-none d-sm-none d-lg-inline-block"
                    >Filter Data</span
                  >
                </button>
                <ng-container
                  *ngIf="
                    this.conditions.showApplyStatus &&
                    this.selectedUserList.length &&
                    this.type === 'incidentlist'
                  "
                >
                  <button
                    style="padding: 0 0.3rem 0 0"
                    (click)="downloadExcelIncident(2)"
                    type="button"
                    class="btn btn-primary"
                  >
                    <em class="fa fa-plus" aria-hidden="true"></em>
                    {{ 'Downloadable Spreadsheet' }}
                  </button>
                </ng-container>
                <div
                  class="custom-dropdown-menu w-100 dropdown-icon"
                  (click)="$event.stopPropagation()"
                  style="margin-top: 64px"
                >
                  <div class="container-fluid">
                    <form #filterForm="ngForm" class="filter-form">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <ng-container *ngFor="let i of tableConfig?.cols">
                              <div
                                class="col-xl-3 col-lg-4 col-sm-6 col-12"
                                *ngIf="i.filter"
                              >
                                <div class="form-group">
                                  <label>{{ i.colName }}</label>

                                  <ng-select
                                    *ngIf="i.colName == 'Status'"
                                    class="ng-select-line"
                                    [items]="statusFilterlist"
                                    bindValue="value"
                                    bindLabel="status"
                                    placeholder="Enter {{ i.colName }}"
                                    [clearable]="false"
                                    [multiple]="true"
                                    [dropdownPosition]="'auto'"
                                    [closeOnSelect]="true"
                                    (focus)="
                                      getSatutsFilterlistData(i.colFieldname)
                                    "
                                    (change)="
                                      selectFilterStatus(i.colFieldname, $event)
                                    "
                                    [(ngModel)]="i.value"
                                    name="status"
                                    (keydown)="
                                      i.colName == 'E-mail'
                                        ? ''
                                        : i.colName == 'Mobile'
                                        ? restrictKeyPressService.AllowNumbers(
                                            $event
                                          )
                                        : restrictKeyPressService.AllowChar(
                                            $event
                                          )
                                    "
                                    required
                                  >
                                  </ng-select>

                                  <app-filter-type-ahead
                                    *ngIf="i.colName !== 'Status'"
                                    [data]="i"
                                    [type]="this.type"
                                    #filterTypeAhead
                                    (selectedValueOfFilter)="
                                      getSelectedFilterValueFromTyeAhead($event)
                                    "
                                  >
                                  </app-filter-type-ahead>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                          <div class="row filter-btn">
                            <div class="col-md-12 text-right">
                              <button
                                type="button"
                                class="nav-link btn btn-primary"
                                style="display: inline-block"
                                (click)="applyFilter()"
                                [disabled]="disableApplyFilter"
                              >
                                Apply Filter
                              </button>
                              <button
                                type="button"
                                class="nav-link btn btn-outline-primary"
                                style="display: inline-block"
                                (click)="resetFilter()"
                                [disabled]="disableResetFilter"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div> -->

              <div
                *ngIf="
                  this.conditions.showApplyStatus &&
                  this.selectedUserList.length
                "
                class="d-flex flex-wrap"
              >
                <button
                  type="button"
                  class="btn btn-default delete-btn minW-0 btn-bg-white"
                  (click)="submitStatusForm('delete')"
                >
                  <i class="bx bx-trash-alt"></i>
                </button>

                <div
                  *ngIf="
                    this.type === 'domainlist' ||
                      this.type === 'departmentlist' ||
                      this.type === 'newslist' ||
                      this.type === 'blogslist' ||
                      this.type === 'designationlist' ||
                      this.type === 'healthcarerolelist' ||
                      this.type === 'expertiselist' ||
                      this.type === 'trainingList' ||
                      this.type === 'positionlist' ||
                      this.type === 'mechanicalFailureList' ||
                      this.type === 'subRegionlist' ||
                      this.type === 'regionlist' ||
                      this.type === 'userlist' ||
                      this.type === 'adminUserlist' ||
                      this.type === 'tagListing';
                    else next
                  "
                >
                  <ng-select
                    [items]="StatusArr"
                    [clearable]="true"
                    bindLabel="name"
                    [(ngModel)]="StatusValue"
                    bindValue="name"
                    name="user"
                    #status="ngModel"
                    required
                    autocomplete="status"
                    [allowClear]="true"
                    placeholder="Select Status"
                    (change)="submitStatusForm('status', $event, this.type)"
                  >
                  </ng-select>
                </div>
                <ng-template #next>
                  <ng-select
                    *ngIf="this.type === 'incidentlist'"
                    [items]="dropdownlist1"
                    [clearable]="true"
                    bindLabel="value"
                    [(ngModel)]="StatusValue"
                    bindValue="id"
                    name="incident"
                    #status="ngModel"
                    required
                    autocomplete="status"
                    [allowClear]="true"
                    placeholder="Select Status"
                    (change)="submitStatusForm('status', $event, this.type)"
                  >
                  </ng-select>
                  <ng-select
                    *ngIf="this.type === 'solutionlist'"
                    [items]="dropdownlist5"
                    [clearable]="true"
                    bindLabel="value"
                    [(ngModel)]="StatusValue"
                    bindValue="id"
                    name="incident"
                    #status="ngModel"
                    required
                    autocomplete="status"
                    [allowClear]="true"
                    placeholder="Select Status"
                    (change)="submitStatusForm('status', $event, this.type)"
                  >
                  </ng-select>
                  <ng-select
                    *ngIf="
                      this.type === 'reportlist' ||
                      this.type === 'reportsolutionlist'
                    "
                    [items]="dropdownList3"
                    [clearable]="true"
                    bindLabel="value"
                    [(ngModel)]="StatusValue"
                    bindValue="id"
                    name="report"
                    #status="ngModel"
                    required
                    autocomplete="status"
                    [allowClear]="true"
                    placeholder="Select Status"
                    (change)="submitStatusForm('status', $event, this.type)"
                  >
                  </ng-select>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="right-buttons d-flex justify-content-end">
              <label *ngIf="this.conditions.search" class="pr-3">
                <div class="search-input-wrapper">
                  <div class="form-group">
                    <em class="bx bx-search"></em>
                    <input
                      type="text"
                      class="form-control icon-search"
                      placeholder="Search"
                      name="search"
                      #search="ngModel"
                      [(ngModel)]="searchText"
                      autocomplete="off"
                      (keyup.enter)="searchData($event)"
                    />
                    <div *ngIf="this.type != 'reachlist'" class="remove-icon">
                      <em
                        class="bx bx-x"
                        *ngIf="searchText"
                        (click)="removeSearch()"
                      ></em>
                    </div>
                    <div *ngIf="this.type === 'reachlist'" class="remove-icon">
                      <em
                        class="bx bx-x"
                        *ngIf="searchText"
                        (click)="removeSearch1()"
                      ></em>
                    </div>
                  </div>
                </div>
              </label>
              <div
                *ngIf="this.conditions.showFilter"
                class="custom-dropdown filter-data-dropdown position-static pr-1"
                [ngClass]="status ? 'open' : ''"
              >
                <span
                  (click)="openFilter()"
                  class="action-ico"
                  type="button"
                  tooltip="Filter"
                >
                  <img
                    class="img-fluid"
                    src="assets/images/icon/bx-filter.svg"
                    alt=""
                  />
                </span>
                <ng-container
                  *ngIf="
                    this.conditions.showApplyStatus &&
                    this.selectedUserList.length &&
                    this.type === 'incidentlist'
                  "
                >
                  <button
                    style="padding: 0 0.3rem 0 0"
                    (click)="downloadExcelIncident(2)"
                    type="button"
                    class="btn btn-primary"
                  >
                    <em class="fa fa-plus" aria-hidden="true"></em>
                    {{ 'Downloadable Spreadsheet' }}
                  </button>
                </ng-container>
                <div
                  class="custom-dropdown-menu w-100 dropdown-icon"
                  (click)="$event.stopPropagation()"
                  style="margin-top: 64px"
                >
                  <div class="container-fluid">
                    <form #filterForm="ngForm" class="filter-form">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <ng-container *ngFor="let i of tableConfig?.cols">
                              <div
                                class="col-xl-3 col-lg-4 col-sm-6 col-12"
                                *ngIf="i.filter"
                              >
                                <div class="form-group">
                                  <label>{{ i.colName }}</label>

                                  <ng-select
                                    *ngIf="i.colName == 'Status'"
                                    class="ng-select-line"
                                    [items]="statusFilterlist"
                                    bindValue="value"
                                    bindLabel="status"
                                    placeholder="Enter {{ i.colName }}"
                                    [clearable]="false"
                                    [multiple]="true"
                                    [dropdownPosition]="'auto'"
                                    [closeOnSelect]="true"
                                    (focus)="
                                      getSatutsFilterlistData(i.colFieldname)
                                    "
                                    (change)="
                                      selectFilterStatus(i.colFieldname, $event)
                                    "
                                    [(ngModel)]="i.value"
                                    name="status"
                                    (keydown)="
                                      i.colName == 'E-mail'
                                        ? ''
                                        : i.colName == 'Mobile'
                                        ? restrictKeyPressService.AllowNumbers(
                                            $event
                                          )
                                        : restrictKeyPressService.AllowChar(
                                            $event
                                          )
                                    "
                                    required
                                  >
                                  </ng-select>

                                  <app-filter-type-ahead
                                    *ngIf="i.colName !== 'Status'"
                                    [data]="i"
                                    [type]="this.type"
                                    #filterTypeAhead
                                    (selectedValueOfFilter)="
                                      getSelectedFilterValueFromTyeAhead($event)
                                    "
                                  >
                                  </app-filter-type-ahead>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                          <div class="row filter-btn">
                            <div class="col-md-12 text-right">
                              <button
                                type="button"
                                class="nav-link btn btn-primary"
                                style="display: inline-block"
                                (click)="applyFilter()"
                                [disabled]="disableApplyFilter"
                              >
                                Apply Filter
                              </button>
                              <button
                                type="button"
                                class="nav-link btn btn-outline-primary"
                                style="display: inline-block"
                                (click)="resetFilter()"
                                [disabled]="disableResetFilter"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div
                *ngIf="this.conditions.showExport"
                class="download-btn download-dropdown pr-3 pl-1"
                [ngClass]="statusDownload ? 'open' : ''"
              >
                <span
                  (click)="openDownloadButton()"
                  class="action-ico"
                  type="button"
                  tooltip="Download"
                  id="downloadDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    class="img-fluid"
                    src="assets/images/icon/bx-download.svg"
                    alt=""
                  />
                </span>
                <div
                  class="download-dropdown-menu pt-3 pr-3 pb-3 pl-0 dropdown-menu-right dropdown-icon"
                  aria-labelledby="downloadDropdown"
                >
                  <div class="container-fluid">
                    <div class="row flex-nowrap">
                      <div class="left-col p-0">
                        <ul
                          class="list-group handle-list border-left-0 mr-2"
                          *ngFor="let i of tableConfig?.cols"
                        >
                          <li
                            *ngIf="i.columnVisibility"
                            class="list-group-item"
                          >
                            <div class="custom-checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  name="status"
                                  (change)="selectColumns(tableConfig?.cols)"
                                  [(ngModel)]="i.isSelected"
                                />
                                <span></span>
                                {{ i.colName }}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div
                        class="right-col p-0 border-top-0 border-bottom-0 border-right-0 border pl-3"
                      >
                        <div class="row m-0">
                          <div class="col-left">
                            <div class="input-group mb-3">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Save Template"
                                [(ngModel)]="options.templateName"
                                #templateName="ngModel"
                              />
                              <div class="input-group-append">
                                <button
                                  (click)="
                                    saveDownloadTemplate(tableConfig?.cols)
                                  "
                                  class="btn btn-primary"
                                  type="button"
                                >
                                  Save Template
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-right">
                            <div class="dropdown colorbox m-0">
                              <button
                                (click)="openCloseColorDropdown()"
                                class="btn dropdown-toggle dropdown-btn"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span
                                  id="downloadDropdownColor"
                                  class="color-box"
                                ></span>
                              </button>
                              <ul
                                class="dropdown-menu dropdown-menu-right dropdown-icon"
                                [ngClass]="statusColorPicker ? 'show' : ''"
                                aria-labelledby="dropdownMenuButton2"
                              >
                                <li (click)="selectColor('#FDAC41', 'yellow')">
                                  <a class="dropdown-item"
                                    ><span class="color-shade bg-yellow"></span
                                    ><span class="color-text">Yellow</span></a
                                  >
                                </li>
                                <li (click)="selectColor('#39DA8A', 'green')">
                                  <a class="dropdown-item"
                                    ><span class="color-shade bg-green"></span
                                    ><span class="color-text">Green</span></a
                                  >
                                </li>
                                <li (click)="selectColor('#b87ee5', 'purple')">
                                  <a class="dropdown-item"
                                    ><span class="color-shade bg-purple"></span
                                    ><span class="color-text">Purple</span></a
                                  >
                                </li>
                                <li (click)="selectColor('#FF5B5C', 'rose')">
                                  <a class="dropdown-item"
                                    ><span class="color-shade bg-rose"></span
                                    ><span class="color-text">Rose</span></a
                                  >
                                </li>
                                <li (click)="selectColor('#b3c0ce', 'gray')">
                                  <a class="dropdown-item"
                                    ><span class="color-shade bg-gray"></span
                                    ><span class="color-text">Gray</span></a
                                  >
                                </li>
                                <li (click)="selectColor('#00cfdd', 'blue')">
                                  <a class="dropdown-item"
                                    ><span class="color-shade bg-skyblue"></span
                                    ><span class="color-text">Sky Blue</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="row m-0 flex-column">
                          <div
                            class="scrollable template-list-outer right-col-list"
                          >
                            <ul class="list-group template-list">
                              <li
                                class="list-group-item d-flex align-items-center flex-wrap"
                                *ngFor="let temp of tempSettingsData"
                              >
                                <div
                                  class="d-flex align-items-center flex-wrap filter-label"
                                >
                                  <span
                                    [style.background-color]="temp.color"
                                    id="filterSquareMini"
                                    class="color-squre-mini mr-1"
                                    [disabled]="true"
                                    [cpOKButtonClass]="'btn btn-primary btn-md'"
                                  ></span>
                                  {{ temp.description }}
                                </div>

                                <span class="d-inline-flex align-items-center">
                                  <a
                                    (click)="
                                      CallAPIdeleteDownloadTemplate(temp)
                                    "
                                    class="d-inline-block cursor-pointer"
                                    ><i class="bx bx-trash-alt"></i
                                  ></a>
                                  <span
                                    class="bx bx-edit text-primary cursor-pointer ml-2"
                                    aria-hidden="true"
                                    tooltip="Edit"
                                    placement="bottom"
                                    (click)="editTemplate(temp)"
                                  ></span>
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div
                            *ngIf="count"
                            class="bottom-buttons border-left-0 border-bottom-0 border-right-0 border mt-2 mb-2 pt-3"
                          >
                            <button
                              (click)="CallAPIdownloadCSVandExcel('excel')"
                              type="button"
                              class="btn border mr-2"
                              id="ExportReporttoExcel"
                            >
                              <span class="mr-2">
                                <img
                                  class="img-fluid"
                                  src="assets/images/icon/excel-icon.svg"
                                  alt="Excel"
                                />
                              </span>
                              Excel
                              <!-- CallAPIdownloadCSVandExcel   -->
                            </button>
                            <button
                              (click)="CallAPIdownloadCSVandExcel('csv')"
                              type="button"
                              class="btn border"
                              id="ExportReporttoCSV"
                            >
                              <span class="mr-2">
                                <img
                                  class="img-fluid"
                                  src="assets/images/icon/csv-icon.svg"
                                  alt="Excel"
                                />
                              </span>
                              CSV
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="this.conditions.showImport"
                class="download-btn download-dropdown pr-3 pl-1"
                [ngClass]="statusImport ? 'open' : ''"
              >
                <button
                  class="btn btn-outline-primary"
                  (click)="openImportButton()"
                  type="button"
                  tooltip="Import"
                  id="downloadDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Import CSV
                </button>
                <div
                  class="download-dropdown-menu pt-3 pr-3 pb-3 pl-0 dropdown-menu-right dropdown-icon"
                  aria-labelledby="downloadDropdown"
                >
                  <div class="container-fluid">
                    <div class="row flex-nowrap">
                      <div class="col-12">
                        <h5>Select Permissions</h5>

                        <div class="permissions d-flex flex-wrap">
                          <div
                            *ngFor="let permission of permissions"
                            class="list-group handle-list mr-3 mb-2"
                          >
                            <div class="list-group-item">
                              <div class="custom-checkbox">
                                <label>
                                  <input
                                    type="checkbox"
                                    (change)="togglePermission(permission)"
                                    [(ngModel)]="permission.isSelected"
                                  />
                                  <span></span>
                                  {{ permission.name }}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-3">
                          <div class="file-drop-area">
                            <div
                              class="drag-drop-zone"
                              (drop)="onDrop($event)"
                              (dragover)="onDragOver($event)"
                            >
                              <img
                                class="img-fluid mb-4"
                                src="assets/images/icon/folder-upload.svg"
                                alt=""
                              />
                              <p>Drag and drop your CSV here</p>
                              <p style="color: #5c5c5c">Or</p>
                              <input
                                type="file"
                                (change)="browseFiles($event)"
                                name="File Upload"
                                id="csvFileUpload"
                                hidden
                                #fileInput
                                accept=".csv"
                              />
                              <button
                                class="btn btn-outline-primary"
                                (click)="fileInput.click()"
                              >
                                Browse Files
                              </button>
                            </div>
                          </div>
                          <div class="cancel-btn mt-3">
                            <span (click)="openImportButton()">Cancel</span>
                          </div>
                          <div *ngIf="failedEmails.length > 0">
                            <h5>Failed Email Ids:</h5>
                            <div *ngFor="let email of failedEmails">
                              <span>{{ email.emailId }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container>
                <button
                  style="border-radius: 2rem"
                  type="button"
                  *ngIf="
                    type === 'preApprovedEmails' && this.conditions.showButton
                  "
                  class="nav-link btn btn-primary listlink"
                  [routerLink]="[URLConstants.ADDEMAILSCSV]"
                >
                  <em class="fa fa-plus" aria-hidden="true"></em>
                  <span>Import CSV</span>
                </button>
              </ng-container>

              <ng-container *ngIf="type === 'questionlist'">
                <button
                  type="button"
                  class="btn btn-primary listlink ml-2"
                  (click)="openModal(type)"
                >
                  <em class="fa fa-plus" aria-hidden="true"></em>
                  <span>Duplicate Questionnaire</span>
                </button>
              </ng-container>
              <ng-container>
                <button
                  style="border-radius: 2rem"
                  type="button"
                  *ngIf="type === 'tagListing' && this.conditions.showButton"
                  class="nav-link btn btn-primary listlink"
                  [routerLink]="[URLConstants.ADDTAGCSV]"
                >
                  <em class="fa fa-plus" aria-hidden="true"></em>
                  <span>Import CSV</span>
                </button>
              </ng-container>
              <ng-container
                *ngIf="this.conditions.showButton && !conditions.modal"
              >
                <button
                  type="button"
                  class="nav-link btn btn-primary listlink"
                  [routerLink]="[this.conditions.showButton.routerLink]"
                >
                  <em class="fa fa-plus" aria-hidden="true"></em>
                  <span>{{ this.conditions.showButton.buttonName }}</span>
                </button>
              </ng-container>
              <ng-container
                *ngIf="this.conditions.showButton && conditions.modal"
              >
                <button
                  type="button"
                  class="btn btn-primary listlink ml-2"
                  (click)="openModal(type)"
                >
                  <em class="fa fa-plus" aria-hidden="true"></em>
                  <span>{{ this.conditions.showButton.buttonName }}</span>
                </button>
              </ng-container>
            </div>
          </div>
          <!-- <div class="col-md-12">
                        <div class="search-label">
                            <div class="multiple-text">
                                <div class="text-label mr-2 mb-2" *ngFor="let f of APIparameters.filter;let i= index">
                                    <label class="mb-0">{{f.key}}<span class="text-success">&nbsp; {{f.type}} </span>&nbsp; {{f.value}}</label>
                                    <label (click)="resetFilter()" class="remove-label">
                                        <em class="bx bx-x" ></em>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
      <table
        cdkDropList
        (cdkDropListDropped)="drops($event, this.type)"
        datatable
        [dtOptions]="dtOptions"
        [dtTrigger]="dtTrigger"
        sortable-table
        (sorted)="onSorted($event)"
        class="custom-table table table-striped"
        [ngClass]="
          (type === 'incidentlist' ||
            type === 'newslist' ||
            type === 'tagListing' ||
            type === 'userlist' ||
            type === 'solutionlist' ||
            type === 'reportlist' ||
            type === 'comments' ||
            type === 'replies' ||
            type === 'domainlist' ||
            type === 'postlist' ||
            type === 'preApprovedEmails' ||
            type === 'recommandedList' ||
            type === 'reportsolutionlist') &&
          'display nowrap table-responsive'
        "
        id="table"
        *ngIf="tableData?.data"
        aria-describedby="table"
        width="100%"
      >
        <thead>
          <tr>
            <th
              [hidden]="!i.isVisible"
              [sortable-column]="i"
              [displaySortIcon]="i.sort"
              *ngFor="let i of tableConfig?.cols"
              id="th1"
              [ngClass]="{
                'text-truncate': i.type == 'html',
                'align-center': i.type == 'symbol',
                'color-sort-column': i.columnSorted
              }"
            >
              <ng-container
                *ngIf="
                  i.type !== 'multipleSelection' &&
                  i.type !== 'switch' &&
                  i.type != 'columnSettings'
                "
              >
                {{ i.colName }}
              </ng-container>
              <ng-container *ngIf="i.type == 'columnSettings'">
                <div class="btn-group" dropdown [insideClick]="true">
                  <em dropdownToggle class="fa fa-cog"> </em>
                  <ul *dropdownMenu class="dropdown-menu" role="menu">
                    <div class="card">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row" class="example-list">
                            <div
                              *ngFor="let setting of colSettingCols"
                              class="example-box"
                              cdkDrag
                              [ngClass]="
                                setting.columnVisibility ? 'col-md-12' : ''
                              "
                            >
                              <ng-container *ngIf="setting.drag">
                                <div class="custom-checkbox">
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="status"
                                      [(ngModel)]="setting.isVisible"
                                      [disabled]="setting.disable"
                                    />
                                    <span></span>
                                    {{ setting.colName }}
                                  </label>
                                </div>
                              </ng-container>
                            </div>
                            <div class="text-right filter-btn">
                              <div class="col-md-12">
                                <button
                                  type="submit"
                                  class="nav-link btn btn-primary ml-2"
                                  (click)="saveColumnSettings(colSettingCols)"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </ng-container>
              <ng-container *ngIf="i.type === 'history'">
                {{ i | json }}
              </ng-container>
              <ng-container *ngIf="i.type == 'switch'">{{
                i.colName
              }}</ng-container>
              <div
                *ngIf="i.type == 'multipleSelection'"
                class="custom-checkbox"
              >
                <label>
                  <input
                    type="checkbox"
                    name="checkbox"
                    [(ngModel)]="i.checkbox"
                    (change)="selectAllUser(i)"
                  />
                  <span></span>
                </label>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            class="example-list"
            class="animated fadeIn"
            *ngFor="let i of tableData?.data; let y = index"
          >
            <td
              [ngClass]="j.type == 'action' ? 'd-flex' : ''"
              [hidden]="!j.isVisible"
              *ngFor="let j of tableConfig?.cols"
            >
              <div
                *ngIf="j.type == 'multipleSelection'"
                class="custom-checkbox"
              >
                <ng-container
                  *ngIf="type === 'promoCodeList'; else basicMultipleSelection"
                >
                  <label *ngIf="i['usedInOrders'].length == 0">
                    <input
                      type="checkbox"
                      name="checkbox"
                      [(ngModel)]="i.checkbox"
                      (change)="selectMultipleUser(i)"
                    /><span></span>
                  </label>
                </ng-container>
                <ng-template #basicMultipleSelection>
                  <label
                    ><input
                      type="checkbox"
                      name="checkbox"
                      [(ngModel)]="i.checkbox"
                      (change)="selectMultipleUser(i)" /><span></span
                  ></label>
                </ng-template>
              </div>

              <div *ngIf="j.type == 'IncidentReport'">
                {{ i.postId.incidentTitle | titlecase }}
              </div>
              <div *ngIf="j.type == 'IncidentSolution'">
                {{ i.postId.title | titlecase }}
              </div>

              <div *ngIf="j.type == 'Post'">
                {{ i[j.colFieldname] ? i[j.colFieldname] : 0 }}
              </div>
              <div *ngIf="j.type == 'switch'">
                <label class="switch">
                  <input
                    type="checkbox"
                    class="success"
                    [(ngModel)]="i[j.colFieldname]"
                    (click)="changeStatus(i)"
                  />
                  <span class="slider round"></span>
                </label>
              </div>
              <div *ngIf="j.type == 'image'" class="thumb-img">
                <span class="caption-image">
                  <img
                    [src]="
                      i[j.colFieldname]
                        ? i[j.colFieldname]
                        : 'assets/images/noImageAvailable.png'
                    "
                    alt="no img"
                  />
                </span>
              </div>
              <div
                *ngIf="
                  j.type == 'dropdown' &&
                    this.type == 'userlist' &&
                    this.industrytype == 'healthcare';
                  else next
                "
              >
                <ng-select
                  appendTo="body"
                  class="ng-select-line"
                  [items]="dropdownList8"
                  bindLabel="id"
                  bindValue="value"
                  placeholder="Select"
                  [(ngModel)]="i[j.colFieldname]"
                  (change)="changeStatus(i)"
                  [clearable]="false"
                >
                </ng-select>
              </div>
              <ng-template #next>
                <div *ngIf="j.type == 'dropdown'">
                  <ng-select
                    appendTo="body"
                    class="ng-select-line"
                    [items]="dropdownList"
                    bindLabel="id"
                    bindValue="value"
                    placeholder="Select"
                    [(ngModel)]="i[j.colFieldname]"
                    (change)="changeStatus(i)"
                    [clearable]="false"
                  >
                  </ng-select>
                </div>
              </ng-template>
              <div *ngIf="j.type == 'enableDisableDropdown'">
                <ng-select
                  appendTo="body"
                  class="ng-select-line"
                  [items]="dropdownList9"
                  bindLabel="id"
                  bindValue="value"
                  placeholder="Select"
                  [(ngModel)]="i[j.colFieldname]"
                  (change)="changeStatus(i)"
                  [clearable]="false"
                  [disabled]="i['isRegistered'] ? true : false"
                >
                </ng-select>
              </div>
              <div *ngIf="j.type == 'dropdownReachUs'">
                <ng-select
                  appendTo="body"
                  class="ng-select-line"
                  [items]="dropdownlistreachus"
                  bindLabel="value"
                  bindValue="id"
                  placeholder="{{
                    i.isResponded ? 'Responded' : 'Not responded'
                  }}"
                  [(ngModel)]="i[j.colFieldname]"
                  (change)="changeStatus1($event, i)"
                  [clearable]="false"
                >
                </ng-select>
              </div>
              <div *ngIf="j.type == 'dropdown1'">
                <ng-select
                  appendTo="body"
                  class="ng-select-line"
                  [items]="
                    i.publicConsumption || i.isAdminPost
                      ? dropdownlistforpublic
                      : dropdownlist1
                  "
                  bindLabel="value"
                  bindValue="id"
                  placeholder="{{
                    i.isSubmitted
                      ? 'Pending'
                      : i.isPublicIncident && !i.isRejected
                      ? 'Public'
                      : i.isApproved
                      ? 'Approved'
                      : i.isRejected
                      ? 'Reject'
                      : 'Pending'
                  }}"
                  [(ngModel)]="i[j.colFieldname]"
                  (change)="changeStatus1($event, i)"
                  [clearable]="false"
                >
                </ng-select>
              </div>
              <div *ngIf="j.type == 'dropdownAdminPost'">
                <ng-select
                  appendTo="body"
                  class="ng-select-line"
                  [items]="dropdownlistforpublicadmin"
                  bindLabel="value"
                  bindValue="id"
                  placeholder="{{
                    i.isPublicIncident && !i.isRejected ? 'Public' : 'Private'
                  }}"
                  [(ngModel)]="i[j.colFieldname]"
                  (change)="changeStatus1($event, i)"
                  [clearable]="false"
                >
                </ng-select>
              </div>
              <div *ngIf="j.type == 'dropdown5'">
                <ng-select
                  appendTo="body"
                  class="ng-select-line"
                  [items]="dropdownlist5"
                  bindLabel="value"
                  bindValue="id"
                  placeholder="{{
                    i.isSubmitted
                      ? 'Pending'
                      : i.isApproved
                      ? 'Approved'
                      : i.isRejected
                      ? 'Reject'
                      : 'Pending'
                  }}"
                  [(ngModel)]="i[j.colFieldname]"
                  (change)="changeStatus1($event, i)"
                  [clearable]="false"
                >
                </ng-select>
              </div>
              <div *ngIf="j.type == 'dropdown2'">
                <ng-select
                  appendTo="body"
                  class="ng-select-line"
                  [items]="dropdownList2"
                  bindLabel="id"
                  bindValue="value"
                  placeholder="{{
                    i.isKept
                      ? 'Keep'
                      : i.isPending
                      ? 'Pending'
                      : i.isRemoved
                      ? 'Removed'
                      : 'select'
                  }}"
                  [(ngModel)]="i[j.colFieldname]"
                  (change)="changeStatus2($event, i)"
                  [clearable]="false"
                >
                </ng-select>
              </div>
              <div *ngIf="j.type == 'dropdown7'">
                <ng-select
                  appendTo="body"
                  class="ng-select-line"
                  [items]="dropdownlist7"
                  bindLabel="id"
                  bindValue="value"
                  placeholder="{{ i.status ? i.status : 'Approved' }}"
                  [(ngModel)]="i[j.colFieldname]"
                  (change)="changeStatus2($event, i)"
                  [clearable]="false"
                >
                </ng-select>
              </div>
              <div *ngIf="j.type == 'dropdown4'">
                <ng-select
                  appendTo="body"
                  class="ng-select-line"
                  [items]="dropdownList4"
                  bindLabel="value"
                  bindValue="value"
                  placeholder="{{ i.isDeleted ? 'Deleted' : 'Pending' }}"
                  [(ngModel)]="i[j.colFieldname]"
                  (change)="changeStatus3($event, i)"
                  [clearable]="false"
                  [disabled]="i.isDeleted ? true : false"
                >
                </ng-select>
              </div>

              <div *ngIf="j.type == 'dropdownDeleteRestore'">
                <ng-select
                  appendTo="body"
                  class="ng-select-line"
                  [items]="i.isDeleted ? dropdownListRestore : dropdownList4"
                  bindLabel="value"
                  bindValue="value"
                  placeholder="{{ i.isDeleted ? 'Deleted' : 'Pending' }}"
                  [(ngModel)]="i[j.colFieldname]"
                  (change)="changeStatus3($event, i)"
                  [clearable]="false"
                >
                </ng-select>
              </div>

              <div *ngIf="j.type == 'dropdownRestore'">
                <ng-select
                  appendTo="body"
                  class="ng-select-line"
                  [items]="dropdownListRestore"
                  bindLabel="value"
                  bindValue="value"
                  placeholder="{{ i.isDeleted ? 'Deleted' : 'Pending' }}"
                  [(ngModel)]="i[j.colFieldname]"
                  (change)="changeStatus3($event, i)"
                  [clearable]="false"
                  [disabled]="i.isDeleted ? false : true"
                >
                </ng-select>
              </div>

              <div style="max-width: 400px" *ngIf="j.type === 'message'">
                <p [class.limitTextHeight]="!i.isReadmore">
                  <span> {{ i[j.colFieldname] }} </span>
                </p>
                <span
                  *ngIf="i[j.colFieldname].length > 100"
                  [ngClass]="i.isReadmore ? 'btncolorR' : 'btncolor'"
                  (click)="i.isReadmore = !i.isReadmore"
                >
                  {{ i.isReadmore ? 'Show less' : 'Show More' }}</span
                >
              </div>

              <div *ngIf="j.type == 'DateTime'">
                {{ i[j.colFieldname] | date : 'dd/MM/yyyy h:mm a' }}
              </div>
              <div *ngIf="j.type == 'Region'">
                <!-- {{ i[j.colFieldname] | titlecase }} -->
                {{ i[j.colFieldname] }}
              </div>

              <div *ngIf="j.type == 'ParentRegion'">
                <!-- {{ i.parentRegion[j.colFieldname] | titlecase }} -->
                {{ i.parentRegion[j.colFieldname] }}
              </div>
              <div *ngIf="j.type == 'imageplus'" class="thumb-img">
                <div class="d-flex align-items-center">
                  <div class="user-image mr-2">
                    <span class="caption-image">
                      <img
                        [src]="
                          i.photo
                            ? i.photo
                            : 'assets/images/noImageAvailable.png'
                        "
                        alt="no img"
                      />
                    </span>
                  </div>
                  <div class="user-name">
                    <div class="text-name">
                      {{ i.fakeUsername }}
                    </div>
                    <div *ngIf="!i.isActive" class="email-text">
                      <div class="email-text">
                        {{ i.emailId }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="j.type == 'typein'">
                <span *ngFor="let i of i[j.colFieldname] | keyvalue">
                  <span *ngIf="i['value']">{{ i['key'] | titlecase }}</span>
                  <br />
                </span>
              </div>

              <div *ngIf="j.type == 'incidentInfo'" class="thumb-img">
                <div class="d-flex align-items-center">
                  <div class="user-image mr-2">
                    <span class="caption-image">
                      <img
                        [src]="
                          i[j.colFieldname]?.photo
                            ? i[j.colFieldname]?.photo
                            : 'assets/images/noImageAvailable.png'
                        "
                        alt="no img"
                      />
                    </span>
                  </div>
                  <div class="user-name mb-1">
                    <div class="text-name mb-1">
                      {{
                        i[j.colFieldname]?.username ||
                          i[j.colFieldname]?.fakeUsername
                      }}
                    </div>
                    <div class="email-text mb-1">
                      {{ i[j.colFieldname]?.emailId }}
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="j.type == 'reportuser'" class="thumb-img">
                <div class="d-flex align-items-center">
                  <div class="user-image mr-2">
                    <span class="caption-image">
                      <img
                        [src]="
                          i[j.colFieldname]?.author?.photo
                            ? i[j.colFieldname]?.author?.photo
                            : 'assets/images/noImageAvailable.png'
                        "
                        alt="no img"
                      />
                    </span>
                  </div>
                  <div class="user-name mb-1">
                    <div class="text-name mb-1">
                      {{
                        i[j.colFieldname]?.author?.username ||
                          i[j.colFieldname]?.author?.fakeUsername
                      }}
                    </div>
                    <div class="email-text mb-1">
                      {{ i[j.colFieldname]?.author?.emailId }}
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="j.type == 'CreateOn'">
                {{ i[j.colFieldname]?.updatedAt | date }}
              </ng-container>
              <ng-container *ngIf="j.type == 'ReportRegion'">
                {{ i[j.colFieldname]?.incidentRegion?.name }}
              </ng-container>
              <div class="row mx-0 px-0" *ngIf="j.type == 'Report'">
                <div class="col mx-3 px-3" (click)="openModal('reportlist', i)">
                  {{ i?.reportedCount }}
                </div>
              </div>
              <ng-container *ngIf="j.type == 'ReportDepartment'">
                {{ i[j.colFieldname]?.incidentDepartment?.name }}
              </ng-container>
              <ng-container *ngIf="j.type == 'ReportIndustry'">
                {{ i[j.colFieldname]?.industry }}
              </ng-container>
              <ng-container *ngIf="j.type == 'action'">
                <div *ngFor="let k of tableConfig?.actions" class="action-icon">
                  <ng-container *ngIf="k.type === 'icon'">
                    <span class="cursor">
                      <em
                        [class]="k.class"
                        aria-hidden="true"
                        [tooltip]="k.tooltip"
                        *ngIf="k.id !== 4 && k.id !== 5"
                        (click)="performAction(k, i)"
                      ></em>
                      <em
                        [class]="k.class"
                        aria-hidden="true"
                        [tooltip]="k.tooltip"
                        *ngIf="
                          k.id === 4 &&
                          i.deleteFeedback !== '' &&
                          i.isActive === false
                        "
                        (click)="performAction(k, i)"
                      ></em>
                      <em
                        [class]="k.class"
                        aria-hidden="true"
                        [tooltip]="k.tooltip"
                        *ngIf="k.id === 5"
                        (click)="performAction(k, i)"
                      ></em>
                    </span>
                  </ng-container>
                  <div *ngIf="k.type === 'button'">
                    <ng-container *ngIf="k.permission">
                      <button
                        type="button"
                        [class]="k.class"
                        (click)="performAction(k, i)"
                      >
                        {{ k.buttonTitle }}
                      </button>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <div *ngIf="j.type == 'symbol'" class="align-center">
                <span *ngIf="i[j.colFieldname] === true">&#10003;</span>
                <span *ngIf="i[j.colFieldname] === false">&#9747;</span>
              </div>
              <div
                *ngIf="j.type == 'addIcon'"
                [routerLink]="[this.URLConstants.BLOG_ADD]"
                class="addIcon"
              >
                <span class="bx bxs-plus-circle"></span>
              </div>
              <ng-container *ngIf="j.type == 'domainRegion'">
                {{ i[j.colFieldname] }}
              </ng-container>
              <ng-container *ngIf="j.type == 'objs'">
                {{ i[j.colFieldname]?.createdAt | date }}
              </ng-container>
              <ng-container *ngIf="j.type == 'region'">
                {{ i[j.colFieldname]?.name }}
              </ng-container>
              <ng-container *ngIf="j.type == 'preApprovedEmailTag'">
                {{ i[j.colFieldname]?.name }}
              </ng-container>
              <ng-container *ngIf="j.type == 'sub-region'">
                {{ i[j.colFieldname]?.name | titlecase }}
              </ng-container>
              <ng-container *ngIf="j.type == 'Department'">
                {{ i[j.colFieldname]?.name | titlecase }}
              </ng-container>
              <ng-container *ngIf="j.type == 'parentIncident'">
                {{ i[j.colFieldname]?.incidentTitle | titlecase }}
              </ng-container>
              <ng-container *ngIf="j.type == 'emailRegistration'">
                {{ i[j.colFieldname] ? 'Registered' : '' }}
              </ng-container>
              <ng-container *ngIf="j.type == 'commentPost'">
                {{
                  i[j?.colFieldname]?.incidentTitle.length > 50
                    ? (i[j?.colFieldname]?.incidentTitle | slice : 0 : 50) +
                      '...'
                    : i[j?.colFieldname]?.incidentTitle
                }}
              </ng-container>
              <ng-container *ngIf="j.type == 'replySolution'">
                {{
                  i[j?.colFieldname]?.title.length > 50
                    ? (i[j?.colFieldname]?.title | slice : 0 : 50) + '...'
                    : i[j?.colFieldname]?.title
                }}
              </ng-container>

              <div
                *ngIf="
                  j.type == 'text' &&
                  j.colFieldname !== 'reportingTo' &&
                  j.colFieldname !== 'technology' &&
                  j.colFieldname !== 'experience' &&
                  j.colFieldname !== 'designation'
                "
              >
                {{
                  j?.isObj
                    ? i[j?.colFieldname][j?.isObj]
                    : j?.isDate
                    ? (i[j?.colFieldname] | date : 'dd MMM yyy')
                    : i[j?.colFieldname]
                    ? i[j?.colFieldname].length > 50
                      ? (i[j?.colFieldname] | slice : 0 : 50) + '...'
                      : i[j?.colFieldname]
                    : '--'
                }}
              </div>
              <div
                *ngIf="j.type == 'html'"
                [innerHTML]="i[j.colFieldname]"
              ></div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Data Table End -->
    </div>
    <!-- TABLE FOOTER START-->
    <div class="card-footer" *ngIf="this.conditions.showTableFooter">
      <div
        class="custom-pagination d-flex align-items-center justify-content-between"
        *ngIf="tableData.data.length"
      >
        <div class="d-flex align-items-center">
          <span class="record-count mr-1">Showing</span>
          <ng-select
            class="ng-select-line"
            (change)="selectItemPerPage(itemsPerPage)"
            [items]="itemsPerPageList"
            placeholder="Select"
            [(ngModel)]="itemsPerPage"
            [clearable]="false"
            [searchable]="false"
            [dropdownPosition]="'auto'"
            [labelForId]="tableData.type"
          >
          </ng-select>
          <span class="record-count ml-2">out of {{ total }} entries</span>
        </div>
        <div class="pagination-list">
          <pagination
            [itemsPerPage]="itemsPerPage"
            #customPagination
            [totalItems]="total"
            (pageChanged)="pagination($event)"
            [maxSize]="5"
            previousText="&laquo;"
            nextText="&raquo;"
            firstText="&laquo;"
            lastText="&raquo;"
          >
          </pagination>
        </div>
        <div class="d-flex align-items-center">
          <span class="record-count mr-1">Go to page</span>
          <input
            type="text"
            class="square-input"
            #inputBox
            (keyup.enter)="onEnter(inputBox.value)"
            maxlength="2"
          />
        </div>
      </div>
      <!-- Pagination End -->
    </div>
    <!-- TABLE FOOTER END-->
  </div>
</div>
