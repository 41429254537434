import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  NgZone,
  OnChanges,
} from '@angular/core';
import { NavigationStart, Router, ActivatedRoute } from '@angular/router';
import { Observable, of, Subject, concat, BehaviorSubject } from 'rxjs';
import {
  merge,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  catchError,
  map,
} from 'rxjs/operators';

// environment
import { environment } from 'src/environments/environment';

// plugins
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxSpinnerService } from 'ngx-spinner';
// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// Services
import { CommonService } from '../../../common/service/common.service';
import { RestrictKeyPressService } from '../../../common/service/restrict-key-press.service';
import { ShowErrorService } from '../../../common/service/show-error.service';

// interfaces

import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { BroadcasterService } from 'src/app/common/service/broadcaster.service';

import { TemplateDetailsComponent } from '../modals/template-details/template-details.component';
import { CommonModalComponent } from '../modals/common-modal/common-modal.component';
import { AddblogComponent } from '../modals/addblog/addblog.component';
import { FaqsListCategoryComponent } from '../modals/faqs-list-category/faqs-list-category.component';
import { FaqsCategoryComponent } from '../modals/faqs-category/faqs-category.component';
import { AddTagComponent } from '../modals/add-tag/add-tag.component';

import { LocalStorageService } from 'src/app/common/service/local-storage.service';
import { AddDepartmentComponent } from '../modals/add-department/add-department.component';
import { AddRegionComponent } from '../modals/add-region/add-region.component';
import { AddTrainingComponent } from '../modals/add-training/add-training.component';
import { AddDomainComponent } from '../modals/add-domain/add-domain.component';
import { AddsubRegionComponent } from '../modals/addsub-region/addsub-region.component';
import { ReportComponent } from '../modals/report/report.component';
import { RemoveReportComponent } from '../modals/remove-report/remove-report.component';
import { ReportSolutionComponent } from '../modals/report-solution/report-solution.component';
import { AddRecommandedComponent } from '../modals/add-recommanded/add-recommanded.component';
import { AddEmailTagComponent } from '../modals/add-email-tag/add-email-tag.component';
import { AddEmailComponent } from '../modals/add-email/add-email.component';
import { DeleteCommentComponent } from '../modals/delete-comment/delete-comment.component';
import { AddQrComponent } from '../modals/add-qr/add-qr.component';
import { AddCommentComponent } from '../modals/add-comment/add-comment.component';
import { AddSolutionComponent } from '../modals/add-solution/add-solution.component';
import { AddExpertiseComponent } from '../modals/add-expertise/add-expertise.component';
import { AddAirlineComponent } from '../modals/add-airline/add-airline.component';
import { AddServiceComponent } from '../modals/add-place-of-service/add-service.component';
import { AddmechanicalFailureComponent } from '../modals/addmechanical-failure/addmechanical-failure.component';
import { AddDesignationComponent } from '../modals/add-designation/add-designation.component';
import { AddHealthcareRoleComponent } from '../modals/add-healthcarerole/add-healthcarerole.component';
import { AddPositionComponent } from '../modals/add-position/add-position.component';
import { AddTranslationComponent } from '../modals/add-translation/add-translation.component';
import { AddSolutionTranslationComponent } from '../modals/add-solution-translation/add-solution-translation.component';
import { AddNewsComponent } from '../modals/add-news/add-news.component';
import { AddNewsTranslationComponent } from '../modals/add-news-translation/add-news-translation.component';
import { AddInfographicTranslationComponent } from '../modals/add-infographic-translation/add-infographic-translation.component';
import { AddTagsTranslationComponent } from '../modals/add-tags-translation/add-tags-translation.component';
import { AddBlogsComponent } from '../modals/add-blogs/add-blogs.component';
import { AddBlogsTranslationComponent } from '../modals/add-blog-translation/add-blogs-translation.component';
import { AddQuestionnaireComponent } from '../modals/add-questionnaire/add-questionnaire.component';
import { DuplicateQuestionnaireComponent } from '../modals/duplicate-questionnaire/duplicate-questionnaire.component';
@Component({
  selector: 'app-responsive-data-table',
  templateUrl: './responsive-data-table.component.html',
  styles: [],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: false } }],
})
export class ResponsiveDataTableComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @ViewChild('filterForm', { static: false }) filterForm;
  callAPIConstants = callAPIConstants;
  public URLConstants = URLConstants;
  public uploadImageUrl = environment.uploadImgUrl;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dropdown: boolean = false;
  APIparameters: any = {};
  options: any = {};
  total: any;
  UserRole: string;
  StatusArr = [
    { id: 1, name: 'Active' },
    { id: 2, name: 'Inactive' },
  ];
  public type: any;
  public industrytype: any;
  public submitted = false;
  public selectedUserList: any[] = [];
  public searchFilters: any[] = [];
  public columnVisibilityOptions = false;
  public filterColumnOption = false;
  public params: any;
  public conditions: any;
  public searchText: any;
  public color: any = '';
  public filterColor: any = '#4184EF';
  public tempSettingsData: any = [];
  showSkeletonLoader1: boolean = true;
  @Input() accessPermission: any;
  @Input() columns: Array<any>;
  @Input() tableSetupData: any;
  public tempData: any = [];
  public tableConfig: any = {};
  tableData = {
    cols: ['id', 'author', 'title'],
    data: [],
    rowSelected: 0,
    value: false,
    type: '',
    subTitle: '',
  };
  @ViewChild('customPagination', { static: false }) customPagination: any;
  public downloadColumnsArr: any = [];
  public columnVisibilityOptions1: any;
  public columnVisibilityOptions2: boolean = false;
  public columnKey: any;
  public isFilterStatus: any = false;
  public columnsAction: any = false;
  downloadColumns: string;
  toggleDownloadButton: boolean;
  toggleImportButton: boolean;
  toggleFilterButton: boolean = false;
  getDowloadTemplates: string;
  downloadTemplatesArr: any = [];
  downloadUserFile: string;
  downloadCsv: any;
  downloadExcel: any;
  link: string;
  cols: any = [];
  techListArray: any = [];
  configs: any = {};
  public colSettingCols: Array<any> = [];
  listingAddUpdateBroadCaster: any;
  calenderData: any = {};
  status: boolean = false;
  statusDownload: boolean = false;
  statusImport: boolean = false;
  statusColorPicker: boolean = false;
  statusFilterColorPicker: boolean = false;
  subHeading: string = '';
  permissions = [
    { name: 'Viwer', isSelected: false },
    { name: 'Submitter', isSelected: false },
    { name: 'Investigator', isSelected: false },
  ];
  selectedPermissions = [];
  emails: string[] = [];
  colors = [
    {
      hex: '#00cfdd',
      name: 'blue',
    },
    {
      hex: '#b3c0ce',
      name: 'gray',
    },
    {
      hex: '#FF5B5C',
      name: 'rose',
    },
    {
      hex: '#b87ee5',
      name: 'purple',
    },
    {
      hex: '#39DA8A',
      name: 'green',
    },
    {
      hex: '#FDAC41',
      name: 'yellow',
    },
  ];
  public postId;
  public incidentId;
  public solutionId;
  public newsId;
  public blogsId;
  public infographicId;
  public tagId;
  public failedEmails: any = {};
  userlist: { condition: string; key: string; type: string; value: string }[];
  @Input()
  get incidentTitle(): string {
    return this._incidentTitle.getValue();
  }
  set incidentTitle(incidentTitle: string) {
    this._incidentTitle.next(incidentTitle);
    if (incidentTitle) {
      this.getMethodName = this.callAPIConstants.IncidentList;
      this.disableResetFilter = false;
      this.APIparameters.filter = [{ incidentTitle: [incidentTitle] }];
      this.callApiToGetData();
    }
  }
  private _incidentTitle = new BehaviorSubject(null);

  @Input()
  get solutionTitle(): string {
    return this._solutionTitle.getValue();
  }
  set solutionTitle(solutionTitle: string) {
    this._solutionTitle.next(solutionTitle);
    if (solutionTitle) {
      this.disableResetFilter = false;
      this.APIparameters.filter = [{ title: [solutionTitle] }];
      this.callApiToGetData();
    }
  }
  private _solutionTitle = new BehaviorSubject(null);

  @Input()
  get deleteSolutionTitle(): string {
    return this._deleteSolutionTitle.getValue();
  }
  set deleteSolutionTitle(deleteSolutionTitle: string) {
    this._deleteSolutionTitle.next(deleteSolutionTitle);
    if (deleteSolutionTitle) {
      console.log(deleteSolutionTitle);
      this.getMethodName = this.callAPIConstants.SolutionList;
      this.disableResetFilter = false;
      this.APIparameters.filter = [{ title: [deleteSolutionTitle] }];
      this.callApiToGetData();
    }
  }
  private _deleteSolutionTitle = new BehaviorSubject(null);

  @Input() filterTable: boolean;
  @Input() deleteFilterTable: boolean;

  @Input()
  get domainName(): string {
    return this._domainName.getValue();
  }
  set domainName(domainName: string) {
    this._domainName.next(domainName);
    if (domainName) {
      this.filterTable = true;
      this.APIparameters['page'] = 1;
      this.APIparameters['pagesize'] = 20;
      this.getMethodName = this.callAPIConstants.SearchRecommended;
      this.APIparameters.filter = { searchText: domainName };
      this.callApiToGetData();
    }
  }
  private _domainName = new BehaviorSubject(null);

  drops(event: CdkDragDrop<string[]>, type?) {
    let dragDropModela = {
      id: this.tableData?.data[event.previousIndex]._id,
      currentIndex: event.currentIndex,
      previousIndex: event.previousIndex,
      filter: this.APIparameters.filter,
      sort: this.APIparameters.sort,
    };
    moveItemInArray(
      this.tableData?.data,
      event.previousIndex,
      event.currentIndex
    );
  }
  selectedColor: '';

  selectColor(hash, col) {
    this.color = col;
    document.getElementById('downloadDropdownColor').style.backgroundColor =
      hash;
    this.openCloseColorDropdown();
  }

  selectFilterColor(hash, col) {
    this.filterColor = col;
    document.getElementById('filterDropdownColor').style.backgroundColor = hash;
    this.openCloseColorFilterDropdown();
  }

  public usersList: any = [];
  public internalAssigneList: Observable<any>;
  public internalAssigneLoader: boolean = false;
  public internalAssigneDataSource = new Subject<string>();
  public focus$ = new Subject<string>();

  active: boolean = false;

  selectedColorName: '';

  selector() {
    if (!this.selectedColor) {
      return 'Color';
    } else {
      return (
        '<span style="background: ' +
        this.selectedColor +
        '"></span> ' +
        this.selectedColorName
      );
    }
  }

  setColor(color, colorName) {
    this.selectedColor = color;
    this.selectedColorName = colorName;
    this.active = false;
  }

  toggleDropdown() {
    this.active = !this.active;
  }
  setfromfilter;
  // open filter button
  openFilter() {
    this.setfromfilter = this.userFlag;
    this.userFlag = '';
    this.status = !this.status;
  }

  // open download button
  openDownloadButton() {
    this.statusDownload = !this.statusDownload;
  }
  openImportButton() {
    this.statusImport = !this.statusImport;
    this.failedEmails = {};
  }

  // open color dropdown
  openCloseColorDropdown() {
    this.statusColorPicker = !this.statusColorPicker;
  }

  // open color filter dropdown
  openColorDropdownFilter() {
    this.statusFilterColorPicker = !this.statusFilterColorPicker;
  }

  // open color filter dropdown
  openCloseColorFilterDropdown() {
    this.statusFilterColorPicker = !this.statusFilterColorPicker;
  }
  // filter related variables
  typeArr = [
    { name: 'contains', value: 'contains' },
    { name: 'greaterThan', value: 'greaterThan' },
    { name: 'lessThan', value: 'lessThan' },
    { name: 'date', value: 'date' },
  ];
  statusFilterList = [
    { name: 'Active', value: true },
    { name: 'Inactive', value: false },
  ];

  public savedFilters: any = [];
  public condition: any = '$and';
  public filteredArr: any = [];
  public submitted1: boolean = false;
  public filterId: any;
  public filterCols: any = [];
  public counter: number = 0;
  BlogDetails: string;
  public userFlag: any;
  constructor(
    public commonService: CommonService,
    public showErrorService: ShowErrorService,
    public localStorageService: LocalStorageService,
    private router: Router,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    public restrictKeyPressService: RestrictKeyPressService,
    public broadCaster: BroadcasterService,
    public zone: NgZone,
    public spinnerService: NgxSpinnerService,
    public activateRoute: ActivatedRoute
  ) {
    let currentPage = this.localStorageService.getToken('currentPage');
    let itemsPerPage = this.localStorageService.getToken('itemsPerPage');
    this.APIparameters = {
      page: currentPage ? parseInt(currentPage) : 1,
      pagesize: itemsPerPage ? parseInt(itemsPerPage) : 10,
    };

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.localStorageService.removeToken('currentPage');
        this.localStorageService.removeToken('itemsPerPage');
      }
    });

    this.listingAddUpdateBroadCaster = this.broadCaster
      .on<boolean>('listingAddUpdate')
      .subscribe((success) => {
        if (success) {
          this.callApiToGetData();
        }
      });
  }
  ngOnChanges() {
    this.userFlag = this.UserRole;
  }
  pagnfilter: any;
  ngOnInit() {
    this.loadTypehead();
    this.addTask();
    this.type = this.tableSetupData.type;
    this.params = this.tableSetupData.params;
    this.conditions = this.tableSetupData.conditions;
    this.industrytype = this.tableSetupData.industry;

    if (this.type === 'postSolutionlist' || this.type === 'postCommentslist') {
      this.activateRoute.params.subscribe((data) => {
        this.APIparameters['incidentId'] = data.id;
        this.postId = data.id;
      });
    } else if (this.type === 'translationlist') {
      this.activateRoute.params.subscribe((data) => {
        this.APIparameters['incidentId'] = data.id;
        this.incidentId = data.id;
      });
    } else if (this.type === 'translationSolutionlist') {
      this.activateRoute.params.subscribe((data) => {
        this.APIparameters['solutionId'] = data.id;
        this.solutionId = data.id;
      });
    } else if (this.type === 'translationNewslist') {
      this.activateRoute.params.subscribe((data) => {
        this.APIparameters['newsId'] = data.id;
        this.newsId = data.id;
      });
    } else if (this.type === 'translationBloglist') {
      this.activateRoute.params.subscribe((data) => {
        this.APIparameters['blogId'] = data.id;
        this.blogsId = data.id;
      });
    } else if (this.type === 'translationTagslist') {
      this.activateRoute.params.subscribe((data) => {
        this.APIparameters['tagId'] = data.id;

        this.tagId = data.id;
        console.log(this.tagId);
      });
    } else if (this.type === 'translationInfographiclist') {
      this.activateRoute.params.subscribe((data) => {
        this.APIparameters['infographicId'] = data.id;
        this.infographicId = data.id;
      });
    }

    this.tableConfig.cols = this.tableSetupData.cols;
    this.tableConfig.actions = this.tableSetupData.actions;
    this.tableConfig.subTitle = this.tableSetupData.subTitle;

    this.subHeading = this.tableConfig.subTitle;
    this.tableConfig.cols.forEach((element) => {
      if (element.drag) {
        this.colSettingCols.push(element);
      }
    });

    /*************************************************************
    @Purpose : Change statusList according to role Permission
    /*************************************************************/

    this.statusList = ['Active', 'Inactive', 'Delete'];
    this.conditions.showApplyStatus = false;
    const index = this.tableConfig.cols.findIndex(
      (o) => o.type === 'multipleSelection'
    );

    if (this.filterTable) {
      this._incidentTitle.subscribe((title) => {
        if (title !== null) {
          if (this.deleteFilterTable) {
            this.getMethodName = this.callAPIConstants.IncidentList;
            this.disableResetFilter = false;
            this.APIparameters.filter = [{ incidentTitle: [title] }];
            this.callApiToGetData();
          } else {
            this.disableResetFilter = false;
            this.APIparameters.filter = [{ incidentTitle: [title] }];
            this.callApiToGetData();
          }
        }
      });

      this._solutionTitle.subscribe((title) => {
        if (title !== null) {
          this.disableResetFilter = false;
          this.APIparameters.filter = [{ title: [title] }];
          this.callApiToGetData();
        }
      });

      this._domainName.subscribe((title) => {
        if (title !== null) {
          this.APIparameters['page'] = 1;
          this.APIparameters['pagesize'] = 20;
          this.getMethodName = this.callAPIConstants.SearchRecommended;
          this.APIparameters.filter = { searchText: title };
          this.callApiToGetData();
        }
      });
    } else {
      this.callApiToGetData();
    }
  }

  addTask() {
    this.filteredArr.push({
      condition: this.condition,
    });
  }

  ngAfterViewInit() {
    this.dtOptions = {
      destroy: true,
      retrieve: true,
      searching: false,
      sorting: false,
      ordering: false,
      autoWidth: false,
      paging: false,
      info: false,
      responsive: false,
      // responsive: {
      //   details: {
      //     renderer: Responsive.renderer.listHiddenNodes(),
      //   },
      // },
      columnDefs: [
        { responsivePriority: 1, targets: 0 },
        { responsivePriority: 1, targets: -1 },
        { responsivePriority: 1, targets: -2 },
      ],
    };
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
    this.listingAddUpdateBroadCaster.unsubscribe();
  }

  /*************************************************************
    @Purpose : Api for List
  /*************************************************************/
  public getMethodName: any;
  public getReadMethod: any;
  public CSV: any;
  public EXCEL: any;
  public DeleteUser: any;
  public ChangeStatus: any;
  public getFilterlist: any;
  public colVisible: any;
  public saveFilter: any;
  public getFilter: any;
  public deleteFilter: any;
  public deleteDownloadTemplate: any;

  async callApiToGetData() {
    if (this.type) {
      if (this.type === 'userlist') {
        this.getMethodName = this.callAPIConstants.CheoUserList;
        this.pagnfilter = this.APIparameters.filter;
        this.downloadCsv = this.callAPIConstants.CheoUserCSVforDataTable;
        this.EXCEL = this.callAPIConstants.CheoUserEXCELforDataTable;
        this.DeleteUser = this.callAPIConstants.CheoUserDeleteUsers;
        this.ChangeStatus = this.callAPIConstants.CheoUserChangeStatus;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.getFilterlist = this.callAPIConstants.CheoUserGetColumnData;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.CheoUserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.colVisible = this.callAPIConstants.CommonColumnVisibleSettings;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        // this.filter.push({ industry: [this.tableSetupData.industry] });
        this.APIparameters.filter = this.filter;
        this.deleteDownloadTemplate =
          this.callAPIConstants.deleteDownloadTemplate;
        this.downloadUserFile = this.callAPIConstants.downloadUserFile;
      } else if (this.type === 'adminUserlist') {
        this.getMethodName = this.callAPIConstants.CheoAdminList;
        this.DeleteUser = this.callAPIConstants.CheoAdminDeleteUsers;
        this.ChangeStatus = this.callAPIConstants.CheoAdminChangeStatus;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.getDowloadTemplates = this.callAPIConstants.getDownloadTemplate;
        this.deleteDownloadTemplate =
          this.callAPIConstants.deleteDownloadTemplate;
        this.downloadUserFile = this.callAPIConstants.CheodownloadAdminUserFile;
        this.getFilter = this.callAPIConstants.CheoAdminGetFilters;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.downloadCsv = this.callAPIConstants.CheoAdminCsv;
        this.EXCEL = this.callAPIConstants.CheoAdminExcel;
      } else if (this.type === 'emailTemplateList') {
        this.getMethodName = this.callAPIConstants.EmailTemplateList;
        this.getFilterlist = this.callAPIConstants.EmailColumnData;
        this.DeleteUser = this.callAPIConstants.DeleteEmailTemplate;
        this.ChangeStatus = this.callAPIConstants.EmailChangeStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'cmslist') {
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'roleList') {
        this.getMethodName = this.callAPIConstants.RoleList;
        this.ChangeStatus = this.callAPIConstants.RoleChangeStatus;
        this.getFilterlist = this.callAPIConstants.RoleGetColumnData;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteRoles;
        this.APIparameters['searchText'] = this.searchText;
      } else if (this.type === 'countriesListing') {
        this.getMethodName = this.callAPIConstants.CountriesListing;
        this.ChangeStatus = this.callAPIConstants.changeCountriesStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteCountries;
      } else if (this.type === 'currencyListing') {
        this.getMethodName = this.callAPIConstants.currenciesListing;
        this.ChangeStatus = this.callAPIConstants.changeCurrenciesStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteCurrencies;
      } else if (this.type === 'timezoneListing') {
        this.getMethodName = this.callAPIConstants.timezoneListing;
        this.ChangeStatus = this.callAPIConstants.changeTimezoneStatus;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteTimezones;
      } else if (this.type == 'faqsListing') {
        this.getMethodName = this.callAPIConstants.FaqsListing;
        this.ChangeStatus = this.callAPIConstants.FaqsStatusChange;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.DeleteFaqs;
      } else if (this.type == 'faqsCategoryListing') {
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.getMethodName = this.callAPIConstants.FaqsCategoryListing;
        this.ChangeStatus = this.callAPIConstants.FaqsCategoryStatus;
        this.DeleteUser = this.callAPIConstants.DeleteFaqsCategory;
      } else if (this.type == 'StaticPageList') {
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.getMethodName = this.callAPIConstants.Listing;
        this.ChangeStatus = this.callAPIConstants.ChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteBlog;
        this.BlogDetails = this.callAPIConstants.GetBlogDetails;
      } else if (this.type == 'blogCategoryList') {
        this.ChangeStatus = this.callAPIConstants.BlogCategoryStatusChange;
        this.DeleteUser = this.callAPIConstants.DeleteBlogCategory;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.getMethodName = this.callAPIConstants.BlogCategoryListing;
      } else if (this.type === 'departmentlist') {
        this.getMethodName = this.callAPIConstants.DepartmentList;
        this.ChangeStatus = this.callAPIConstants.StatusDepart;
        this.DeleteUser = this.callAPIConstants.DeleteDepart;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.DepartmentCsv;
        this.EXCEL = this.callAPIConstants.DepartmentExcel;
      } else if (this.type === 'newslist') {
        this.getMethodName = this.callAPIConstants.NewsList;
        this.ChangeStatus = this.callAPIConstants.StatusNews;
        this.DeleteUser = this.callAPIConstants.DeleteNews;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.NewsCsv;
        this.EXCEL = this.callAPIConstants.NewsExcel;
      } else if (this.type === 'blogslist') {
        this.getMethodName = this.callAPIConstants.BlogsList;
        this.ChangeStatus = this.callAPIConstants.StatusBlogs;
        this.DeleteUser = this.callAPIConstants.DeleteBlogs;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.BlogsCsv;
        this.EXCEL = this.callAPIConstants.BlogsExcel;
      } else if (this.type === 'designationlist') {
        this.getMethodName = this.callAPIConstants.DesignationList;
        this.ChangeStatus = this.callAPIConstants.StatusDesignation;
        this.DeleteUser = this.callAPIConstants.DeleteDesignation;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.DesignationCsv;
        this.EXCEL = this.callAPIConstants.DesignationExcel;
      } else if (this.type === 'positionlist') {
        this.getMethodName = this.callAPIConstants.PositionList;
        this.ChangeStatus = this.callAPIConstants.StatusPosition;
        this.DeleteUser = this.callAPIConstants.DeletePosition;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.PositionCsv;
        this.EXCEL = this.callAPIConstants.PositionExcel;
      } else if (this.type === 'healthcarerolelist') {
        this.getMethodName = this.callAPIConstants.HealthcareRoleList;
        this.ChangeStatus = this.callAPIConstants.StatusHealthcareRole;
        this.DeleteUser = this.callAPIConstants.DeleteHealthcareRole;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.HealthcareRoleCsv;
        this.EXCEL = this.callAPIConstants.HealthcareRoleExcel;
      } else if (this.type === 'expertiseList') {
        this.getMethodName = this.callAPIConstants.ExpertiseList;
        this.ChangeStatus = this.callAPIConstants.StatusExpertise;
        this.DeleteUser = this.callAPIConstants.DeleteExpertise;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.DepartmentCsv;
        this.EXCEL = this.callAPIConstants.DepartmentExcel;
      } else if (this.type === 'mechanicalFailureList') {
        this.getMethodName = this.callAPIConstants.MechanicalFailureList;
        this.ChangeStatus = this.callAPIConstants.StatusMechanicalFailure;
        this.DeleteUser = this.callAPIConstants.DeleteMechanicalFailure;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.MechanicalFailureCsv;
        this.EXCEL = this.callAPIConstants.MechanicalFailureExcel;
      } else if (this.type === 'regionlist') {
        this.getMethodName = this.callAPIConstants.RegionList;
        this.ChangeStatus = this.callAPIConstants.StatusRegion;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.downloadCsv = this.callAPIConstants.CsvRegion;
        this.EXCEL = this.callAPIConstants.ExcelRegion;
        this.DeleteUser = this.callAPIConstants.DeleteRegion;
      } else if (this.type === 'questionlist') {
        this.getMethodName = this.callAPIConstants.QuestionnaireList;
        this.ChangeStatus = this.callAPIConstants.Statusquestionnaire;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.downloadCsv = this.callAPIConstants.CsvRegion;
        this.EXCEL = this.callAPIConstants.ExcelRegion;
        this.DeleteUser = this.callAPIConstants.Deletequestionnaire;
      } else if (this.type === 'trainingList') {
        this.getMethodName = this.callAPIConstants.TrainingList;
        this.ChangeStatus = this.callAPIConstants.StatusTraining;
        this.DeleteUser = this.callAPIConstants.DeleteTraining;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.DepartmentCsv;
        this.EXCEL = this.callAPIConstants.DepartmentExcel;
      } else if (this.type === 'airlineList') {
        this.getMethodName = this.callAPIConstants.AirlineList;
        this.ChangeStatus = this.callAPIConstants.StatusAirline;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.downloadCsv = this.callAPIConstants.CsvRegion;
        this.EXCEL = this.callAPIConstants.ExcelRegion;
        this.DeleteUser = this.callAPIConstants.DeleteAirline;
      } else if (this.type === 'serviceList') {
        this.getMethodName = this.callAPIConstants.ServiceList;
        this.ChangeStatus = this.callAPIConstants.StatusService;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.downloadCsv = this.callAPIConstants.CsvRegion;
        this.EXCEL = this.callAPIConstants.ExcelRegion;
        this.DeleteUser = this.callAPIConstants.DeleteService;
      } else if (this.type === 'tagListing') {
        this.getMethodName = this.callAPIConstants.TagGetList;
        this.DeleteUser = this.callAPIConstants.DeleteTag;
        this.ChangeStatus = this.callAPIConstants.StatusTag;
        this.downloadCsv = this.callAPIConstants.TagCsv;
        this.EXCEL = this.callAPIConstants.TagExcel;
      } else if (this.type === 'domainlist') {
        this.getMethodName = this.callAPIConstants.DomainList;
        this.DeleteUser = this.callAPIConstants.DeleteDomain;
        this.ChangeStatus = this.callAPIConstants.StatusDomain;
        this.downloadCsv = this.callAPIConstants.DomainCsv;
        this.EXCEL = this.callAPIConstants.DomainExcel;
      } else if (this.type === 'recommandedList') {
        if (this.filterTable) {
          this.APIparameters['page'] = 1;
          this.APIparameters['pagesize'] = 20;
          this.getMethodName = this.callAPIConstants.SearchRecommended;
          this.DeleteUser = this.callAPIConstants.DeleteDomain;
          this.ChangeStatus = this.callAPIConstants.StatusRecommended;
          this.downloadCsv = this.callAPIConstants.DownloadCSvRecommended;
          this.EXCEL = this.callAPIConstants.DownloadExcelRecommended;
        } else {
          this.getMethodName = this.callAPIConstants.Recommended_list;
          this.DeleteUser = this.callAPIConstants.DeleteDomain;
          this.ChangeStatus = this.callAPIConstants.StatusRecommended;
          this.downloadCsv = this.callAPIConstants.DownloadCSvRecommended;
          this.EXCEL = this.callAPIConstants.DownloadExcelRecommended;
        }
      } else if (this.type === 'subRegionlist') {
        this.getMethodName = this.callAPIConstants.SubRegionList;
        this.ChangeStatus = this.callAPIConstants.StatusSubRegion;
        this.DeleteUser = this.callAPIConstants.DeleteSubRegion;
        this.downloadCsv = this.callAPIConstants.SubRegionCsvDownload;
        this.EXCEL = this.callAPIConstants.SubRegionExcelDownload;
      } else if (this.type === 'incidentlist') {
        this.getMethodName = this.callAPIConstants.IncidentList;
        this.ChangeStatus = this.callAPIConstants.IncidentChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteIncident;
        this.downloadCsv = this.callAPIConstants.IncidentCsv;
        this.EXCEL = this.callAPIConstants.IncidentExcel;
        if (!this.filterTable) {
          // this.filter.push({ industry: [this.tableSetupData.industry] });
          this.APIparameters.filter = this.filter;
        }
      } else if (this.type === 'solutionlist') {
        this.getMethodName = this.callAPIConstants.SolutionList;
        this.ChangeStatus = this.callAPIConstants.SolutionChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteSolution;
        this.downloadCsv = this.callAPIConstants.SolutionCsv;
        this.EXCEL = this.callAPIConstants.SolutionExcel;
      } else if (this.type === 'reportlist') {
        this.APIparameters['type'] = 'Incident';
        this.getMethodName = this.callAPIConstants.ReportList;
        this.ChangeStatus = this.callAPIConstants.ReportChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteSolution;
        this.downloadCsv = this.callAPIConstants.ReportCsvDownload;
        this.EXCEL = this.callAPIConstants.ReportExcelDownload;
      } else if (this.type === 'reportsolutionlist') {
        this.APIparameters['type'] = 'Solution';
        this.getMethodName = this.callAPIConstants.ReportList;
        this.ChangeStatus = this.callAPIConstants.ReportChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteReportcontent;
        this.downloadCsv = this.callAPIConstants.ReportCsvDownload;
        this.EXCEL = this.callAPIConstants.ReportExcelDownload;
      } else if (this.type === 'contentlist') {
        this.getMethodName = this.callAPIConstants.contentList;
        this.DeleteUser = this.callAPIConstants.DeleteContent;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'IncidentDeletelist') {
        if (this.deleteFilterTable) {
          this.getMethodName = this.callAPIConstants.IncidentList;
          this.disableResetFilter = false;
          this.APIparameters.filter = [
            { incidentTitle: [this._incidentTitle.getValue()] },
          ];
          this.ChangeStatus = this.callAPIConstants.DeleteReportStatus;
        } else {
          this.getMethodName = this.callAPIConstants.DeleteReportList;
          this.ChangeStatus = this.callAPIConstants.DeleteReportStatus;
        }
      } else if (this.type === 'SolutionDeletelist') {
        if (this.deleteFilterTable) {
          this.getMethodName = this.callAPIConstants.SolutionList;
          this.disableResetFilter = false;
          this.APIparameters.filter = [
            { title: [this._deleteSolutionTitle.getValue()] },
          ];
          this.ChangeStatus = this.callAPIConstants.DeleteReportStatusSolution;
        } else {
          this.getMethodName = this.callAPIConstants.DeleteListSolution;
          this.ChangeStatus = this.callAPIConstants.DeleteReportStatusSolution;
        }
      } else if (this.type === 'reachlist') {
        this.getMethodName = this.callAPIConstants.Reachlist;
        this.DeleteUser = this.callAPIConstants.DeleteReachUs;
        this.ChangeStatus = this.callAPIConstants.ChangeReachUsStatus;
      } else if (this.type === 'postlist') {
        this.getMethodName = this.callAPIConstants.PostList;
        this.DeleteUser = this.callAPIConstants.DeletePost;
        this.ChangeStatus = this.callAPIConstants.IncidentChangeStatus;
      } else if (this.type === 'infographicList') {
        this.getMethodName = this.callAPIConstants.InfographicList;
        this.DeleteUser = this.callAPIConstants.DeleteInfographic;
      } else if (this.type === 'emailTags') {
        this.getMethodName = this.callAPIConstants.EmailTagList;
        this.DeleteUser = this.callAPIConstants.EmailTagDelete;
        this.ChangeStatus = this.callAPIConstants.EmailTagsChangeStatus;
      } else if (this.type === 'preApprovedEmails') {
        this.getMethodName = this.callAPIConstants.EmailList;
        this.DeleteUser = this.callAPIConstants.EmailDelete;
        this.ChangeStatus = this.callAPIConstants.EmailStatus;
      } else if (this.type === 'comments') {
        this.getMethodName = this.callAPIConstants.CommentList;
        this.DeleteUser = this.callAPIConstants.DeleteComment;
      } else if (this.type === 'qrCampaign') {
        this.getMethodName = this.callAPIConstants.QrList;
        this.DeleteUser = this.callAPIConstants.DeleteQr;
      } else if (this.type === 'replies') {
        this.getMethodName = this.callAPIConstants.replyList;
        this.DeleteUser = this.callAPIConstants.DeleteReply;
      } else if (this.type === 'postSolutionlist') {
        this.getMethodName = this.callAPIConstants.GetSolutionsForPost;
      } else if (this.type === 'postCommentslist') {
        this.getMethodName = this.callAPIConstants.GetCommentsForPost;
      } else if (this.type === 'translationlist') {
        this.getMethodName = this.callAPIConstants.GetTranslationByID;
        this.ChangeStatus = this.callAPIConstants.TranslationChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteTranslation;
      } else if (this.type === 'translationSolutionlist') {
        this.getMethodName = this.callAPIConstants.GetSolutionTranslationByID;
        this.ChangeStatus = this.callAPIConstants.TranslationChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteTranslation;
      } else if (this.type === 'translationNewslist') {
        this.getMethodName = this.callAPIConstants.GetNewsTranslationByID;
        this.ChangeStatus = this.callAPIConstants.TranslationChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteTranslation;
      } else if (this.type === 'translationInfographiclist') {
        this.getMethodName =
          this.callAPIConstants.GetInfographicTranslationByID;
        this.ChangeStatus = this.callAPIConstants.TranslationChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteTranslation;
      } else if (this.type === 'translationTagslist') {
        this.getMethodName = this.callAPIConstants.GetTagTranslationsById;
        this.ChangeStatus = this.callAPIConstants.TranslationChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteTranslation;
      } else if (this.type === 'translationBloglist') {
        this.getMethodName = this.callAPIConstants.GetBlogTranslationByID;
        this.ChangeStatus = this.callAPIConstants.TranslationChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteTranslation;
      }
    }
    this.spinnerService.show();
    if (this.getMethodName) {
      await this.commonService
        .callApi(
          this.getMethodName,
          this.APIparameters,
          'post',
          false,
          false,
          false
        )
        .then((success) => {
          this.spinnerService.hide();
          this.tableData.data = [];
          if (success.status === 1) {
            this.tableData.data = success.data.listing;
            this.tempSettingsData = success.data.templateSettings;
            this.showSkeletonLoader1 = false;
            this.tempSettingsData = success.data.templateSettings;
            if (this.type === 'reachlist') {
              this.tableData.data.forEach((res) => {
                res['isReadmore'] = false;
              });
            }

            if (
              success.data.templateSettings &&
              success.data.templateSettings.length
            ) {
              var hash = this.colors.find((a) => a.name == 'green').hex;
              document.getElementById(
                'downloadDropdownColor'
              ).style.backgroundColor = hash;
              document.getElementById(
                'filterDropdownColor'
              ).style.backgroundColor = hash;
            }

            if (
              success.data.latestColumns &&
              success.data.latestColumns.length
            ) {
              this.colSettingCols = [];
              let arr = [];
              success.data.latestColumns.forEach((e, lindex) => {
                if (e.drag) {
                  this.colSettingCols.push(e);
                }
                if (e.filter) {
                  this.filterCols.push(e);
                }
                this.tableSetupData.cols.forEach((c, colIndex) => {
                  if (c.colName === e.colName) {
                    arr = this.arraymove(
                      this.tableSetupData.cols,
                      colIndex,
                      lindex
                    );
                  } else if (c.colName === 'Select') {
                    this.tableSetupData.cols.splice(colIndex, 1);
                  }
                });
              });
              this.tableSetupData.cols = arr;
              setTimeout(() => {
                this.tableData.data = [];
                this.tableConfig.cols = [];
                this.tableSetupData.cols.unshift({
                  type: 'multipleSelection',
                  colName: 'Select',
                  colFieldname: '',
                  isVisible: true,
                });
                this.tableConfig.cols = this.tableSetupData.cols;
                this.colSettingCols = this.colSettingCols;
                this.tableData.data = success.data.listing;
                this.tableConfig.cols.forEach(async (element) => {
                  if (
                    success.data.columnSettings &&
                    this.type !== 'adminUserlist1'
                  ) {
                    success.data.columnSettings.forEach((dataApi) => {
                      if (element.colFieldname === dataApi.key) {
                        element.isVisible = dataApi.status;
                      }
                    });
                  } else {
                    if (this.columns && this.columns.length) {
                      this.columns.forEach((dataApi) => {
                        if (element.colFieldname === dataApi.key) {
                          element.isVisible = dataApi.status;
                        }
                      });
                    }
                  }
                });

                const index = this.tableConfig.cols.findIndex(
                  (o) => o.type === 'switch'
                );
                if (this.tableConfig.cols[index]) {
                  this.tableConfig.cols[index].isVisible = false;
                  this.tableConfig.cols[index].filter = false;
                  if (this.tableConfig.cols[index].columnVisibility) {
                    this.tableConfig.cols[index].columnVisibility = false;
                  }
                }
                // }
              }, 0);
            } else {
              this.getLatestData(success);
            }

            this.total = success.total;
            this.savedFilters = success.data.filterSettings;
          } else {
            this.showErrorService.popToast('error', 'Something Went Wrong!');
          }
        });
    }
  }
  async callApiToGetData1() {
    if (this.type) {
      if (this.type === 'userlist') {
        this.getReadMethod = this.callAPIConstants.CheoSearchUser;
        this.downloadCsv = this.callAPIConstants.CheoUserCSVforDataTable;
        this.EXCEL = this.callAPIConstants.CheoUserEXCELforDataTable;
        this.DeleteUser = this.callAPIConstants.CheoUserDeleteUsers;
        this.ChangeStatus = this.callAPIConstants.CheoUserChangeStatus;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.getFilterlist = this.callAPIConstants.CheoUserGetColumnData;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.colVisible = this.callAPIConstants.CommonColumnVisibleSettings;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.deleteDownloadTemplate =
          this.callAPIConstants.deleteDownloadTemplate;
        this.downloadUserFile = this.callAPIConstants.downloadUserFile;
      } else if (this.type === 'adminUserlist') {
        this.getReadMethod = this.callAPIConstants.CheoAdminSearch;
        this.DeleteUser = this.callAPIConstants.CheoAdminDeleteUsers;
        this.ChangeStatus = this.callAPIConstants.CheoAdminChangeStatus;
        this.downloadColumns = this.callAPIConstants.SaveDownloadTemplate;
        this.getDowloadTemplates = this.callAPIConstants.getDownloadTemplate;
        this.deleteDownloadTemplate =
          this.callAPIConstants.deleteDownloadTemplate;
        this.downloadUserFile = this.callAPIConstants.downloadAdminUserFile;
        this.getFilter = this.callAPIConstants.CheoAdminGetFilters;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.downloadCsv = this.callAPIConstants.CheoAdminCsv;
        this.EXCEL = this.callAPIConstants.CheoAdminExcel;
      } else if (this.type === 'roleList') {
        this.getMethodName = this.callAPIConstants.RoleList;
        this.ChangeStatus = this.callAPIConstants.RoleChangeStatus;
        this.getFilterlist = this.callAPIConstants.RoleGetColumnData;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.DeleteUser = this.callAPIConstants.deleteRoles;
        this.APIparameters['searchText'] = this.searchText;
      } else if (this.type === 'departmentlist') {
        this.getReadMethod = this.callAPIConstants.DepartmentSearch;
        this.ChangeStatus = this.callAPIConstants.StatusDepart;
        this.DeleteUser = this.callAPIConstants.DeleteDepart;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.DepartmentCsv;
        this.EXCEL = this.callAPIConstants.DepartmentExcel;
      } else if (this.type === 'newslist') {
        this.getReadMethod = this.callAPIConstants.NewsSearch;
        this.ChangeStatus = this.callAPIConstants.StatusNews;
        this.DeleteUser = this.callAPIConstants.DeleteNews;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.NewsCsv;
        this.EXCEL = this.callAPIConstants.NewsExcel;
      } else if (this.type === 'blogslist') {
        this.getReadMethod = this.callAPIConstants.BlogsSearch;
        this.ChangeStatus = this.callAPIConstants.StatusBlogs;
        this.DeleteUser = this.callAPIConstants.DeleteBlogs;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.BlogsCsv;
        this.EXCEL = this.callAPIConstants.BlogsExcel;
      } else if (this.type === 'designationlist') {
        this.getReadMethod = this.callAPIConstants.DesignationSearch;
        this.ChangeStatus = this.callAPIConstants.StatusDesignation;
        this.DeleteUser = this.callAPIConstants.DeleteDesignation;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.DesignationCsv;
        this.EXCEL = this.callAPIConstants.DesignationExcel;
      } else if (this.type === 'positionlist') {
        this.getReadMethod = this.callAPIConstants.PositionSearch;
        this.ChangeStatus = this.callAPIConstants.StatusPosition;
        this.DeleteUser = this.callAPIConstants.DeletePosition;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.PositionCsv;
        this.EXCEL = this.callAPIConstants.PositionExcel;
      } else if (this.type === 'healthcarerolelist') {
        this.getReadMethod = this.callAPIConstants.HealthcareRoleSearch;
        this.ChangeStatus = this.callAPIConstants.StatusHealthcareRole;
        this.DeleteUser = this.callAPIConstants.DeleteHealthcareRole;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.HealthcareRoleCsv;
        this.EXCEL = this.callAPIConstants.HealthcareRoleExcel;
      } else if (this.type === 'expertiseList') {
        this.getReadMethod = this.callAPIConstants.ExpertiseSearch;
        this.ChangeStatus = this.callAPIConstants.StatusExpertise;
        this.DeleteUser = this.callAPIConstants.DeleteExpertise;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.DepartmentCsv;
        this.EXCEL = this.callAPIConstants.DepartmentExcel;
      } else if (this.type === 'mechanicalFailureList') {
        this.getReadMethod = this.callAPIConstants.MechanicalFailureseSearch;
        this.ChangeStatus = this.callAPIConstants.StatusMechanicalFailure;
        this.DeleteUser = this.callAPIConstants.DeleteMechanicalFailure;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.MechanicalFailureCsv;
        this.EXCEL = this.callAPIConstants.MechanicalFailureExcel;
      } else if (this.type === 'regionlist') {
        this.getReadMethod = this.callAPIConstants.SearchRegion;
        this.ChangeStatus = this.callAPIConstants.StatusRegion;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.downloadCsv = this.callAPIConstants.CsvRegion;
        this.EXCEL = this.callAPIConstants.ExcelRegion;
        this.DeleteUser = this.callAPIConstants.DeleteRegion;
      } else if (this.type === 'questionlist') {
        this.getReadMethod = this.callAPIConstants.Searchquestionnaire;
        this.ChangeStatus = this.callAPIConstants.Statusquestionnaire;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.downloadCsv = this.callAPIConstants.CsvRegion;
        this.EXCEL = this.callAPIConstants.ExcelRegion;
        this.DeleteUser = this.callAPIConstants.Deletequestionnaire;
      } else if (this.type === 'trainingList') {
        this.getReadMethod = this.callAPIConstants.SearchTraining;
        this.ChangeStatus = this.callAPIConstants.StatusTraining;
        this.DeleteUser = this.callAPIConstants.DeleteTraining;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
        this.downloadCsv = this.callAPIConstants.DepartmentCsv;
        this.EXCEL = this.callAPIConstants.DepartmentExcel;
      } else if (this.type === 'airlineList') {
        this.getReadMethod = this.callAPIConstants.SearchAirline;
        this.ChangeStatus = this.callAPIConstants.StatusAirline;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.downloadCsv = this.callAPIConstants.CsvRegion;
        this.EXCEL = this.callAPIConstants.ExcelRegion;
        this.DeleteUser = this.callAPIConstants.DeleteAirline;
      } else if (this.type === 'serviceList') {
        this.getMethodName = this.callAPIConstants.SearchService;
        this.ChangeStatus = this.callAPIConstants.StatusService;
        this.saveFilter = this.callAPIConstants.saveFilter;
        this.getFilter = this.callAPIConstants.UserGetFilters;
        this.deleteFilter = this.callAPIConstants.deleteFilter;
        this.downloadCsv = this.callAPIConstants.CsvRegion;
        this.EXCEL = this.callAPIConstants.ExcelRegion;
        this.DeleteUser = this.callAPIConstants.DeleteService;
      } else if (this.type === 'tagListing') {
        this.getReadMethod = this.callAPIConstants.TagSearch;
        this.DeleteUser = this.callAPIConstants.DeleteTag;
        this.ChangeStatus = this.callAPIConstants.StatusTag;
        this.downloadCsv = this.callAPIConstants.TagCsv;
        this.EXCEL = this.callAPIConstants.TagExcel;
      } else if (this.type === 'domainlist') {
        this.getReadMethod = this.callAPIConstants.DomainSearch;
        this.DeleteUser = this.callAPIConstants.DeleteDomain;
        this.ChangeStatus = this.callAPIConstants.StatusDomain;
        this.downloadCsv = this.callAPIConstants.DomainCsv;
        this.EXCEL = this.callAPIConstants.DomainExcel;
      } else if (this.type === 'subRegionlist') {
        this.getReadMethod = this.callAPIConstants.SearchSubRegion;
        this.ChangeStatus = this.callAPIConstants.StatusSubRegion;
        this.DeleteUser = this.callAPIConstants.DeleteSubRegion;
        this.downloadCsv = this.callAPIConstants.SubRegionCsvDownload;
        this.EXCEL = this.callAPIConstants.SubRegionExcelDownload;
      } else if (this.type === 'translationlist') {
        this.ChangeStatus = this.callAPIConstants.TranslationChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteTranslation;
      } else if (this.type === 'translationSolutionlist') {
        this.ChangeStatus = this.callAPIConstants.TranslationChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteTranslation;
      } else if (this.type === 'translationNewslist') {
        this.ChangeStatus = this.callAPIConstants.TranslationChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteTranslation;
      } else if (this.type === 'translationBloglist') {
        this.ChangeStatus = this.callAPIConstants.TranslationChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteTranslation;
        let isBlog = true;
      } else if (this.type === 'translationTagslist') {
        this.ChangeStatus = this.callAPIConstants.TranslationChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteTranslation;
      } else if (this.type === 'translationTagslist') {
        this.ChangeStatus = this.callAPIConstants.TranslationChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteTranslation;
      } else if (this.type === 'translationInfographiclist') {
        this.ChangeStatus = this.callAPIConstants.TranslationChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteTranslation;
      } else if (this.type === 'incidentlist') {
        this.getReadMethod = this.callAPIConstants.IncidentSearch;
        this.ChangeStatus = this.callAPIConstants.IncidentChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteIncident;
        this.downloadCsv = this.callAPIConstants.IncidentCsv;
        this.EXCEL = this.callAPIConstants.IncidentExcel;
      } else if (this.type === 'solutionlist') {
        this.getReadMethod = this.callAPIConstants.SolutionSearch;
        this.ChangeStatus = this.callAPIConstants.SolutionChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteSolution;
        this.downloadCsv = this.callAPIConstants.SolutionCsv;
        this.EXCEL = this.callAPIConstants.SolutionExcel;
      } else if (this.type === 'reportlist') {
        this.APIparameters['type'] = 'Incident';
        this.getMethodName = this.callAPIConstants.ReportList;
        this.ChangeStatus = this.callAPIConstants.ReportChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteSolution;
        this.downloadCsv = this.callAPIConstants.ReportCsvDownload;
        this.EXCEL = this.callAPIConstants.ReportExcelDownload;
      } else if (this.type === 'reportsolutionlist') {
        this.APIparameters['type'] = 'Solution';
        this.getMethodName = this.callAPIConstants.ReportList;
        this.ChangeStatus = this.callAPIConstants.ReportChangeStatus;
        this.DeleteUser = this.callAPIConstants.DeleteReportcontent;
        this.downloadCsv = this.callAPIConstants.ReportCsvDownload;
        this.EXCEL = this.callAPIConstants.ReportExcelDownload;
      } else if (this.type === 'contentlist') {
        this.getReadMethod = this.callAPIConstants.ContentSearch;
        this.DeleteUser = this.callAPIConstants.DeleteContent;
        this.APIparameters.columnKey = this.tableSetupData.saveColumnKey;
      } else if (this.type === 'IncidentDeletelist') {
        this.getReadMethod = this.callAPIConstants.DeleteReportList;
        this.ChangeStatus = this.callAPIConstants.DeleteReportStatus;
      } else if (this.type === 'reachlist') {
        this.getMethodName = this.callAPIConstants.Reachlist;
      } else if (this.type === 'recommandedList') {
        this.getReadMethod = this.callAPIConstants.SearchRecommended;
        this.DeleteUser = this.callAPIConstants.DeleteDomain;
        this.ChangeStatus = this.callAPIConstants.StatusRecommended;
        this.downloadCsv = this.callAPIConstants.DownloadCSvRecommended;
        this.EXCEL = this.callAPIConstants.DownloadExcelRecommended;
      } else if (this.type === 'postlist') {
        this.getMethodName = this.callAPIConstants.PostList;
        this.DeleteUser = this.callAPIConstants.DeletePost;
      } else if (this.type === 'infographicList') {
        this.getMethodName = this.callAPIConstants.InfographicList;
        this.DeleteUser = this.callAPIConstants.DeleteInfographic;
      } else if (this.type === 'emailTags') {
        this.getReadMethod = this.callAPIConstants.EmailTagsSearch;
        this.DeleteUser = this.callAPIConstants.EmailTagDelete;
        this.ChangeStatus = this.callAPIConstants.EmailTagsChangeStatus;
      } else if (this.type === 'preApprovedEmails') {
        this.getReadMethod = this.callAPIConstants.EmailSearch;
        this.DeleteUser = this.callAPIConstants.EmailDelete;
        this.ChangeStatus = this.callAPIConstants.EmailStatus;
      } else if (this.type === 'comments') {
        this.getReadMethod = this.callAPIConstants.SearchComment;
        this.DeleteUser = this.callAPIConstants.DeleteComment;
      }
    }
    this.spinnerService.show();
    if (this.getReadMethod) {
      await this.commonService
        .callApi(
          this.getReadMethod,
          this.APIparameters,
          'post',
          false,
          false,
          false
        )
        .then((success) => {
          this.spinnerService.hide();
          this.tableData.data = [];
          if (success.status === 1) {
            this.tableData.data = success.data.listing;
            this.tempSettingsData = success.data.templateSettings;
            this.showSkeletonLoader1 = false;
            this.tempSettingsData = success.data.templateSettings;
            if (this.type === 'reachlist') {
              this.tableData.data.forEach((res) => {
                res['isReadmore'] = false;
              });
            }

            if (
              success.data.templateSettings &&
              success.data.templateSettings.length
            ) {
              var hash = this.colors.find((a) => a.name == 'green').hex;
              document.getElementById(
                'downloadDropdownColor'
              ).style.backgroundColor = hash;
              document.getElementById(
                'filterDropdownColor'
              ).style.backgroundColor = hash;
            }

            if (
              success.data.latestColumns &&
              success.data.latestColumns.length
            ) {
              this.colSettingCols = [];
              let arr = [];
              success.data.latestColumns.forEach((e, lindex) => {
                if (e.drag) {
                  this.colSettingCols.push(e);
                }
                if (e.filter) {
                  this.filterCols.push(e);
                }
                this.tableSetupData.cols.forEach((c, colIndex) => {
                  if (c.colName === e.colName) {
                    arr = this.arraymove(
                      this.tableSetupData.cols,
                      colIndex,
                      lindex
                    );
                  } else if (c.colName === 'Select') {
                    this.tableSetupData.cols.splice(colIndex, 1);
                  }
                });
              });
              this.tableSetupData.cols = arr;
              setTimeout(() => {
                this.tableData.data = [];
                this.tableConfig.cols = [];
                this.tableSetupData.cols.unshift({
                  type: 'multipleSelection',
                  colName: 'Select',
                  colFieldname: '',
                  isVisible: true,
                });
                this.tableConfig.cols = this.tableSetupData.cols;
                this.colSettingCols = this.colSettingCols;
                this.tableData.data = success.data.listing;
                this.tableConfig.cols.forEach(async (element) => {
                  if (
                    success.data.columnSettings &&
                    this.type !== 'adminUserlist1'
                  ) {
                    success.data.columnSettings.forEach((dataApi) => {
                      if (element.colFieldname === dataApi.key) {
                        element.isVisible = dataApi.status;
                      }
                    });
                  } else {
                    if (this.columns && this.columns.length) {
                      this.columns.forEach((dataApi) => {
                        if (element.colFieldname === dataApi.key) {
                          element.isVisible = dataApi.status;
                        }
                      });
                    }
                  }
                });

                const index = this.tableConfig.cols.findIndex(
                  (o) => o.type === 'switch'
                );
                if (this.tableConfig.cols[index]) {
                  this.tableConfig.cols[index].isVisible = false;
                  this.tableConfig.cols[index].filter = false;
                  if (this.tableConfig.cols[index].columnVisibility) {
                    this.tableConfig.cols[index].columnVisibility = false;
                  }
                }
                // }
              }, 0);
            } else {
              this.getLatestData(success);
            }

            this.total = success.total;
            this.savedFilters = success.data.filterSettings;
          } else {
            this.showErrorService.popToast('error', 'Something Went Wrong!');
          }
        });
    }
  }
  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  }
  ///filter for
  loadTypehead() {
    this.getAsigneSearchList('', false);
    this.internalAssigneList = concat(
      of([]),
      this.internalAssigneDataSource.pipe(
        debounceTime(700),
        merge(this.focus$),
        distinctUntilChanged(),
        switchMap((term) => this.getAsigneSearchList(term, false)),
        map((response) => {
          return response;
        })
      )
    );
  }

  public notFoundText1: any;
  public updatedUsersData: any = [];
  getAsigneSearchList(value?, allow?): Observable<any[]> {
    this.internalAssigneLoader = true;
    var searchText = value;
    let items = [];
    let dataObj = {
      page: 1,
      pagesize: 20,
      column: 'name',
      filter: [
        {
          name: [searchText],
        },
      ],
    };
    return this.commonService
      .callApiObservable(this.callAPIConstants.GetColumnRegion, dataObj)
      .pipe(
        catchError(() => of({ items: [] })),
        map((success: any) => {
          if (success['status'] == 1) {
            this.notFoundText1 = 'No Data Found';
            items = success.data.listing;
            this.updatedUsersData = success.data.listing;
            this.internalAssigneLoader = false;
          } else {
            this.showErrorService.popToast('error', success['message']);
          }
          return items ? items : [];
        })
      );
  }
  getLatestData(success) {
    this.tableConfig = this.tableSetupData;
    this.configs = this.tableSetupData;
    this.cols = [];
    this.filterCols = [];
    this.tableConfig.cols.forEach((element) => {
      if (element.drag) {
        this.cols.push(element);
      }
      if (element.filter) {
        this.filterCols.push(element);
      }
    });

    this.tableConfig.cols.forEach(async (element) => {
      if (success.data.columnSettings && this.type !== 'adminUserlist1') {
        success.data.columnSettings.forEach((dataApi) => {
          if (element.colFieldname === dataApi.key) {
            element.isVisible = dataApi.status;
          }
        });
      } else {
        if (this.columns && this.columns.length) {
          this.columns.forEach((dataApi) => {
            if (element.colFieldname === dataApi.key) {
              element.isVisible = dataApi.status;
            }
          });
        }
      }
    });

    this.tableData.data = success.data.listing;
    this.rerenderDataTable();
  }
  // *************************************************************//
  saveColumnSettings(tableColumnSettings) {
    this.CallAPIchangeColumnVisibility(tableColumnSettings);
  }
  /********************************************************************************
  @Purpose :  Api for downloading CSV/Excel/pdf file of datatable( with and without filter )
  /********************************************************************************/
  public count: any;
  public errorMsg = '';

  selectColumns(tableConfigCols) {
    this.conditions.showApplyStatus = true;

    this.count = tableConfigCols.some((ele) => {
      if (ele.isSelected) {
        return true;
      }
    });
    if (!this.count) {
      delete this.options.templateName;
    }
  }

  toggleDownload() {
    this.toggleDownloadButton = !this.toggleDownloadButton;
    this.downloadColumnsArr = [];
    this.tableConfig.cols.forEach((ele) => {
      ele.isSelected = false;
    });
    delete this.options.templateName;
    this.count = false;
    if (this.toggleDownloadButton) {
      this.CallAPIgetDownloadTemplates();
    }
  }
  saveDownloadTemplate(tableConfigCols) {
    this.submitted = true;
    this.downloadColumnsArr = [];
    tableConfigCols.forEach((element) => {
      if (element.columnVisibility) {
        this.downloadColumnsArr.push({
          key: element.colFieldname,
          status: element.isSelected,
        });
      }
    });
    const data: any = {
      columns: this.downloadColumnsArr,
      color: this.color == '' ? 'green' : this.color,
      description: this.options.templateName,
    };
    if (this.tableSetupData.saveColumnKey) {
      data.key = this.tableSetupData.saveColumnKey;
    }
    if (this.options._id) {
      data.templateId = this.options._id;
    }
    if (!this.downloadColumnsArr.find((a) => a.status == true)) {
      this.showErrorService.popToast(
        'error',
        'Please select atleast one checkbox'
      );
    } else if (this.options.templateName) {
      this.commonService
        .callApi(this.downloadColumns, data, 'post')
        .then((success) => {
          if (success.status === 1) {
            this.CallAPIgetDownloadTemplates();
            this.toggleDownload();
            this.showErrorService.popToast(
              'success',
              'template saved successfully'
            );
            this.callApiToGetData();
          } else {
            this.showErrorService.popToast('error', success.message);
          }
        });
    }
  }
  CallAPIdeleteDownloadTemplate(i) {
    this.showErrorService
      .confirmpopToast('warning', 'Are you sure,You Want To Delete ?')
      .then((result) => {
        if (result.value === true) {
          this.commonService
            .callApi(
              this.deleteDownloadTemplate + i._id,
              '',
              'delete',
              false,
              false
            )
            .then((success) => {
              if (success.status === 1) {
                this.showErrorService.popToast('success', success.message);
                this.callApiToGetData();
                this.CallAPIgetDownloadTemplates();
                this.toggleDownload();
              } else {
                this.showErrorService.popToast('error', success.message);
              }
            });
        }
      });
  }

  showTemplate(i?) {
    this.modalService.show(TemplateDetailsComponent, {
      initialState: { columns: i.columns, type: this.type },
      ignoreBackdropClick: true,
      keyboard: false,
      class: 'modal-lg modal-dialog-centered',
    });
  }
  public downloadUrl: any = environment.downloadFileUrl;
  downloadTemplate(key) {
    let templateData: any = { columns: [] };
    key == 'csv' ? (templateData.type = 'csv') : (templateData.type = key);
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility) {
        if (element.isSelected) {
          templateData.columns.push(element.colFieldname);
        }
      }
    });
  }

  editTemplate(i) {
    this.tableConfig.cols.forEach((ele) => {
      ele.isSelected = false;
    });
    this.options = i;
    this.options.templateName = i.description;
    this.color = i.color;
    var hash = this.colors.find((a) => a.name == i.color).hex;
    document.getElementById('downloadDropdownColor').style.backgroundColor =
      hash;
    this.count = true;
    this.tableConfig.cols.forEach((ele) => {
      i.columns.forEach((ele1) => {
        if (ele1.status && ele1.key == ele.colFieldname) {
          ele.isSelected = true;
        }
      });
    });
  }
  methodChange(i, key) {
    i.method = key;
  }

  removeTask(index) {
    this.filteredArr.splice(index, 1);
  }

  conditionChange(key) {
    this.condition = key;
  }

  applyLatestFilter(form?, item?) {
    this.submitted = true;

    if (this.type === 'userlist') {
      let filter = {
        condition: this.pagnfilter[0].condition,
        key: this.pagnfilter[0].key,
        type: this.pagnfilter[0].type,
        value: this.pagnfilter[0].value,
      };
      this.APIparameters = {
        page: 1,
        pagesize: 10,
        columnKey: this.APIparameters.columnKey,
        filter: this.filteredArr,
      };

      this.APIparameters.filter.push(filter);

      if (form && form.valid) {
        this.APIparameters = {
          page: 1,
          pagesize: 10,
          columnKey: this.APIparameters.columnKey,
          filter: this.filteredArr,
        };
        this.callApiToGetData();
      } else {
        this.APIparameters = {
          page: 1,
          pagesize: 10,
          columnKey: this.APIparameters.columnKey,
          filter: item.filter,
          condition: item.condition,
        };

        this.callApiToGetData();
      }
    } else {
      if (form && form.valid) {
        this.APIparameters = {
          page: 1,
          pagesize: 10,
          columnKey: this.APIparameters.columnKey,
          filter: this.filteredArr,
        };
        this.callApiToGetData();
      } else {
        this.APIparameters = {
          page: 1,
          pagesize: 10,
          columnKey: this.APIparameters.columnKey,
          filter: item.filter,
          condition: item.condition,
        };
        this.callApiToGetData();
      }
    }
    if (this.status) {
      this.status = !this.status;
    }
  }
  /********************************************************************************
  @Purpose :  Api for downloading CSV or Excel file of datatable( with and without filter )
  /********************************************************************************/
  public download = {};
  public isFilter: boolean;

  CallAPIdownloadCSVandExcel(data, isFilter?) {
    const downloadArr: any[] = [];
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility) {
        if (element.isSelected) {
          downloadArr.push(element.colFieldname);
        }
      }
    });

    if (this.type == 'incidentlist') {
      isFilter = true;
    }

    isFilter
      ? (this.download = {
          columns: downloadArr,
          filter: this.filter,
        })
      : (this.download = {
          columns: downloadArr,
        });
    if (this.type === 'reportlist' || this.type === 'reportsolutionlist') {
      this.type === 'reportlist'
        ? (this.download['type'] = 'Incident')
        : (this.download['type'] = 'Solution');
      data === 'csv'
        ? this.commonService
            .callApi(this.downloadCsv, this.download, 'post', false, false)
            .then((success) => {
              window.open(success.data, '_blank');
            })
        : this.commonService
            .callApi(this.EXCEL, this.download, 'post', false, false)
            .then((success) => {
              window.open(success.data, '_blank');
            });
    } else {
      data === 'csv'
        ? this.commonService
            .callApi(this.downloadCsv, this.download, 'post', false, false)
            .then((success) => {
              window.open(success.data, '_blank');
            })
        : this.commonService
            .callApi(this.EXCEL, this.download, 'post', false, false)
            .then((success) => {
              window.open(success.data, '_blank');
            });
    }
  }

  /*************************************************************
    @Purpose : select users and change status
  /*************************************************************/
  public statusFormData = { status: '' };
  public statusList: any[];

  selectMultipleUser(data) {
    if (data.checkbox) {
      if (this.selectedUserList.length) {
        const x = this.selectedUserList.findIndex((o) => o === data._id);
        if (x === -1) {
          this.selectedUserList.push(data._id);
        }
      } else {
        this.selectedUserList.push(data._id);
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.checkbox = false;
      });
      const x = this.selectedUserList.findIndex((o) => o === data._id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    }
  }
  StatusValue: false;
  submitStatusForm(statusFormData, event?, type?) {
    if (this.type === 'reportlist') {
      if (this.selectedUserList.length) {
        if (statusFormData === 'delete') {
          this.showErrorService
            .confirmpopToast('warning', 'Do you want to delete??')
            .then((result) => {
              if (result.value === true) {
                const data = {
                  [this.params.deleteParams]: this.selectedUserList,
                };
                this.CallAPIchangeStatusORDelete(this.DeleteUser, data);
              } else {
                const index = this.tableConfig.cols.findIndex(
                  (o) => o.type === 'multipleSelection'
                );
                this.tableConfig.cols[index].checkbox = false;
                this.deselectMultipleUser();
              }
            });
        } else if (statusFormData === 'status') {
          let statusID = this.tableSetupData.params.statusParams;
          let data = {
            [statusID]: this.selectedUserList,
            status: event.id,
            type: 'Incident',
          };
          this.CallAPIchangeStatusORDelete(this.ChangeStatus, data);
        }
      } else {
        this.showErrorService.popToast('error', 'Select  list not Modify');
      }
      this.deselectMultipleUser();
    } else if (this.type === 'reportsolutionlist') {
      if (this.selectedUserList.length) {
        if (statusFormData === 'delete') {
          this.showErrorService
            .confirmpopToast('warning', 'Do you want to delete??')
            .then((result) => {
              if (result.value === true) {
                const data = {
                  [this.params.deleteParams]: this.selectedUserList,
                };
                this.CallAPIchangeStatusORDelete(this.DeleteUser, data);
              } else {
                const index = this.tableConfig.cols.findIndex(
                  (o) => o.type === 'multipleSelection'
                );
                this.tableConfig.cols[index].checkbox = false;
                this.deselectMultipleUser();
              }
            });
        } else if (statusFormData === 'status') {
          let statusID = this.tableSetupData.params.statusParams;
          let data = {
            [statusID]: this.selectedUserList,
            status: event.id,
            type: 'Solution',
          };
          this.CallAPIchangeStatusORDelete(this.ChangeStatus, data);
        }
      } else {
        this.showErrorService.popToast('error', 'Select  list not Modify');
      }
      this.deselectMultipleUser();
    } else if (this.type === 'incidentlist') {
      if (this.selectedUserList.length) {
        if (statusFormData === 'delete') {
          this.showErrorService
            .confirmpopToast('warning', 'Do you want to delete??')
            .then((result) => {
              if (result.value === true) {
                const data = {
                  [this.params.deleteParams]: this.selectedUserList,
                };
                this.CallAPIchangeStatusORDelete(this.DeleteUser, data);
              } else {
                const index = this.tableConfig.cols.findIndex(
                  (o) => o.type === 'multipleSelection'
                );
                this.tableConfig.cols[index].checkbox = false;
                this.deselectMultipleUser();
              }
            });
        } else if (statusFormData === 'status') {
          let statusID = this.tableSetupData.params.statusParams;
          let data = {
            [statusID]: this.selectedUserList,
            incidentStatus: event.id,
          };
          this.CallAPIchangeStatusORDelete(this.ChangeStatus, data);
        }
      } else {
        this.showErrorService.popToast('error', 'Select  list not Modify');
      }
      this.deselectMultipleUser();
    } else if (this.type === 'solutionlist') {
      if (this.selectedUserList.length) {
        if (statusFormData === 'delete') {
          this.showErrorService
            .confirmpopToast('warning', 'Do you want to delete??')
            .then((result) => {
              if (result.value === true) {
                const data = {
                  [this.params.deleteParams]: this.selectedUserList,
                };
                this.CallAPIchangeStatusORDelete(this.DeleteUser, data);
              } else {
                const index = this.tableConfig.cols.findIndex(
                  (o) => o.type === 'multipleSelection'
                );
                this.tableConfig.cols[index].checkbox = false;
                this.deselectMultipleUser();
              }
            });
        } else if (statusFormData === 'status') {
          let statusID = this.tableSetupData.params.statusParams;
          let data = {
            [statusID]: this.selectedUserList,
            solutionStatus: event.id,
          };
          this.CallAPIchangeStatusORDelete(this.ChangeStatus, data);
        }
      } else {
        this.showErrorService.popToast('error', 'Select  list not Modify');
      }
      this.deselectMultipleUser();
    } else {
      if (this.selectedUserList.length) {
        if (statusFormData === 'delete') {
          this.showErrorService
            .confirmpopToast('warning', 'Do you want to delete??')
            .then((result) => {
              if (result.value === true) {
                const data = {
                  [this.params.deleteParams]: this.selectedUserList,
                };
                this.CallAPIchangeStatusORDelete(this.DeleteUser, data);
              } else {
                const index = this.tableConfig.cols.findIndex(
                  (o) => o.type === 'multipleSelection'
                );
                this.tableConfig.cols[index].checkbox = false;
                this.deselectMultipleUser();
              }
            });
        } else if (statusFormData === 'status') {
          if (event.name === 'Inactive' || event.name === 'Active') {
            let data;
            let statusID = this.tableSetupData.params.statusParams;

            if (
              this.type === 'userlist' ||
              this.type === 'domainlist' ||
              this.type === 'subRegionlist' ||
              this.type === 'regionlist' ||
              this.type === 'tagListing' ||
              this.type === 'departmentlist' ||
              this.type === 'newslist' ||
              this.type === 'blogslist' ||
              this.type === 'positionlisting' ||
              this.type === 'designationlist' ||
              this.type === 'healthcarerolelist'
            ) {
              event.name === 'Inactive'
                ? (data = {
                    [statusID]: this.selectedUserList,
                    isActive: false,
                  })
                : (data = {
                    [statusID]: this.selectedUserList,
                    isActive: true,
                  });
            } else {
              event.name === 'Inactive'
                ? (data = { [statusID]: this.selectedUserList, status: false })
                : (data = { [statusID]: this.selectedUserList, status: true });
            }

            this.CallAPIchangeStatusORDelete(this.ChangeStatus, data);
            this.deselectMultipleUser();
          }
        }
      } else {
        this.showErrorService.popToast('error', 'Select Users To Modify');
      }
    }
    // statusForm.reset();
  }
  downloadExcelIncident(statusFormData) {
    let dataObj;
    //DownLoadExcelIncident
    if (this.selectedUserList.length) {
      dataObj = {
        incidentIds: this.selectedUserList,
      };
      this.commonService
        .callApi(
          this.callAPIConstants.DownLoadExcelIncident,
          dataObj,
          'post',
          false,
          false
        )
        .then((success) => {
          window.open(success.path, '_blank');
        });
    }
  }
  // *************************************************************//

  /***************************************************************
    @Purpose : Pagination
  /***************************************************************/
  public itemsPerPageList = [10, 25, 50, 150, 250, 500];
  itemsPerPage = 10;
  currentPage = this.localStorageService.getToken('currentPage')
    ? parseInt(this.localStorageService.getToken('currentPage'))
    : 1;

  onEnter(value: string): void {
    const page = Number(value);
    if (page && page > 0) {
      this.updatePagination(page, this.itemsPerPage);
    } else {
      console.warn('Invalid page number');
    }
  }

  pagination(e): void {
    this.updatePagination(e.page, e.itemsPerPage);
  }

  updatePagination(page: number, itemsPerPage: number): void {
    this.userFlag = this.UserRole;
    this.localStorageService.setToken('currentPage', page);
    this.localStorageService.setToken('itemsPerPage', itemsPerPage);
    this.deselectMultipleUser();
    this.APIparameters.page = page;
    this.itemsPerPage = itemsPerPage;
    this.columnVisibilityOptions2 = false;
    this.selectedUserList = [];

    if (this.searchflag) {
      this.callApiToGetData1();
    } else {
      this.callApiToGetData();
    }
    this.customPagination.selectPage(page);
  }

  selectItemPerPage(e) {
    if (this.searchflag) {
      this.searchflag = true;
      this.customPagination._page = 1;
      this.APIparameters.pagesize = e;
      this.APIparameters.page = 1;
      this.columnVisibilityOptions2 = false;
      this.selectedUserList = [];
      this.callApiToGetData1();
      this.deselectMultipleUser();
    } else {
      this.customPagination._page = 1;
      this.APIparameters.pagesize = e;
      this.APIparameters.page = 1;
      this.columnVisibilityOptions2 = false;
      this.selectedUserList = [];
      this.callApiToGetData();
      this.deselectMultipleUser();
    }
  }

  /***************************************************************/

  /***************************************************************
    @Purpose : change visibility of columns
  /***************************************************************/

  public columnVisibleArr: any[] = [];

  tooglecolumnVisibilityFilter() {
    this.columnVisibilityOptions = !this.columnVisibilityOptions;
    this.filterColumnOption = false;
  }

  importCSVandExcel(key) {
    if (this.type == 'countriesListing') {
      if (key == 'csv') {
        // this.commonService.router.navigate([URLConstants.COUNTRYBULKUPLOAD, 'master', this.type, 'csv'])
      } else {
        // this.commonService.router.navigate([URLConstants.COUNTRYBULKUPLOAD, 'master', this.type, 'excel'])
      }
    }
  }

  changeCheckBoxValue() {
    let count = 0;
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility === true && element.isVisible === true) {
        count++;
      }
    });
    if (count === 1) {
      const index = this.tableConfig.cols.findIndex(
        (o) => o.isVisible === true && o.columnVisibility === true
      );
      if (index > -1 && this.tableConfig.cols[index]) {
        this.tableConfig.cols[index].disable = true;
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.disable = false;
      });
    }
  }

  CallAPIchangeColumnVisibility(tableConfigCols) {
    tableConfigCols.forEach((element) => {
      if (element.columnVisibility) {
        this.columnVisibleArr.push({
          key: element.colFieldname,
          status: element.isVisible,
        });
      }
    });

    const data: any = { columns: this.columnVisibleArr };
    if (this.tableSetupData.saveColumnKey) {
      data.key = this.tableSetupData.saveColumnKey;
      data.latestColumns = this.colSettingCols;
    }

    this.commonService
      .callApi(this.colVisible, data, 'post', false, false)
      .then((success) => {
        success.status === 1
          ? this.showErrorService.popToast('success', 'Updated Successfully')
          : this.showErrorService.popToast('error', success.message);
        this.callApiToGetData();
      });
    this.columnVisibleArr = [];
  }

  selectAllColumnVisible() {
    this.tableConfig.cols.forEach((element) => {
      element.isVisible = true;
    });
  }

  resetColumnVisible() {
    this.callApiToGetData();
  }
  /***************************************************************/

  /*************************************************************
  @Purpose    : Api for change Status and Delete
  @parameters : callAPi ==> name of Api to be called
                data ==> data to be send
  /*************************************************************/
  CallAPIchangeStatusORDelete(callAPI, data) {
    this.commonService
      .callApi(callAPI, data, 'post', false, false, false)
      .then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          setTimeout(() => {
            this.callApiToGetData();
          });
          this.selectedUserList = [];
        } else {
          this.showErrorService.popToast('error', 'Something Went Wrong!');
        }
      });
  }

  // *************************************************************//

  /*************************************************************
    @Purpose :  Rerendering Datatable
  /*************************************************************/
  async rerenderDataTable() {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    } else {
      this.dtTrigger.next();
    }
  }
  // *************************************************************//

  /*************************************************************
    @Purpose : Sorting for user listing
  /*************************************************************/

  onSorted(event) {
    this.APIparameters.sort = {};
    event.sortDirection === 'desc'
      ? (event.sortDirection = -1)
      : (event.sortDirection = 1);
    let sort_index = this.tableConfig.cols.findIndex(
      (e) => e.colFieldname == event.sortColumn.colFieldname
    );
    this.tableConfig.cols[sort_index]['columnSorted'] = true;
    this.APIparameters.sort[event.sortColumn.colFieldname] =
      event.sortDirection;
    this.callApiToGetData();
  }
  /***************************************************************/

  /***************************************************************
    @Purpose :  Perform Action: VIEW, EDIT, DELETE And SWITCH
  /***************************************************************/

  performAction(k, i) {
    // VIEW action
    if (k.id === 1) {
      if (this.type === 'userlist') {
        this.router.navigate([this.URLConstants.USERS_DETAIL, i._id]);
      } else if (this.type === 'StaticPageList') {
        this.router.navigate([this.URLConstants.VIEW_STATIC_DETAILS, i._id]);
      } else if (this.type === 'adminUserlist') {
        this.router.navigate([
          this.URLConstants.USERS_DETAIL,
          i._id,
          { type: 'admin' },
        ]);
      }
    } else if (k.id === 2) {
      if (this.type === 'adminUserlist') {
        this.router.navigate([this.URLConstants.EDITADMINUSER, i._id]);
      } else if (this.type === 'userlist') {
        this.router.navigate([this.URLConstants.EDITUSERS, i._id]);
      } else if (this.type === 'solutionlist') {
        this.router.navigate([this.URLConstants.EDITSOLUTION, i._id]);
      } else if (this.type === 'qrCampaign') {
        this.router.navigate([this.URLConstants.VIEWQRCAMPAIGN, i._id]);
      } else if (this.type === 'emailTemplateList') {
        this.router.navigate([this.URLConstants.EMAIL_TEMPLATE_detail, i._id]);
      } else if (this.type === 'incidentlist') {
        if (i.industry === 'healthcare') {
          this.router.navigate([this.URLConstants.EDITINCIDENT, i._id]);
        }

        if (i.industry === 'aviation') {
          this.router.navigate([this.URLConstants.EDITINCIDENTAVIATION, i._id]);
        }
      } else if (this.type === 'reportlist') {
        this.router.navigate([this.URLConstants.EDITINCIDENT, i.postId._id]);
      } else if (this.type === 'postlist') {
        this.router.navigate([this.URLConstants.EDITPOST, i._id]);
      } else if (this.type === 'infographicList') {
        this.router.navigate([this.URLConstants.EDITGRAPHIC, i._id]);
      } else if (this.type === 'contentlist') {
        this.router.navigate([this.URLConstants.CMS_DETAIL, i._id]);
      } else if (this.type === 'roleList') {
        this.router.navigate([this.URLConstants.EDIT_ROLE, i._id]);
      } else if (this.type === 'countriesListing') {
        this.modalService.show(CommonModalComponent, {
          initialState: { type: this.type, id: i._id },
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
        });
      } else if (this.type === 'userlist') {
        this.router.navigate([this.URLConstants.ADDUPDATEUSER, i._id]);
      } else if (this.type === 'blogCategoryList') {
        this.modalService.show(AddblogComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              blogCategory_Id: i._id,
              type: 'edit_blog_category',
              title: 'Update Blog Category',
            },
          },
        });
      } else if (this.type === 'faqsCategoryListing') {
        this.modalService.show(FaqsCategoryComponent, {
          ignoreBackdropClick: false,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              faqsCategory_id: i._id,
              type1: this.type,
              type: 'edit_faqs_category',
              title: 'Update FAQs Category',
            },
          },
        });
      } else if (this.type === 'faqsListing') {
        this.modalService.show(FaqsListCategoryComponent, {
          ignoreBackdropClick: false,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              faqs_id: i._id,
              type1: this.type,
              type: 'edit_faqs_listing',
              title: 'Update FAQs',
            },
          },
        });
      } else if (this.type === 'currencyListing') {
        this.modalService.show(CommonModalComponent, {
          initialState: { type: this.type, id: i._id },
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
        });
      } else if (this.type === 'timezoneListing') {
        this.modalService.show(CommonModalComponent, {
          initialState: { type: this.type, id: i._id },
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
        });
      } else if (this.type === 'StaticPageList') {
        this.router.navigate([this.URLConstants.EDIT_STATIC_PAGE, i._id]);
      } else if (this.type === 'tagListing') {
        this.modalService.show(AddTagComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              _id: i._id,
              name: i.name,
              isActive: i.isActive,
              definition: i.definition,
              keywords: i.keywords,
              industry: i.industry,
              language: i?.language,
              parentId: i?.parentId,
              type: 'edit_taglist',
              title: 'Edit Tag',
            },
          },
        });
      } else if (this.type === 'subRegionlist') {
        this.modalService.show(AddsubRegionComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              subRegionId: i._id,
              name: i?.name,
              isActive: i.isActive,
              parentRegion: i.parentRegion,
              type: 'edit_list',
              title: 'Edit Tag',
            },
          },
        });
      } //subRegionlist
      else if (this.type === 'domainlist') {
        this.modalService.show(AddDomainComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              domainId: i._id,
              name: i?.name,
              subRegion: i?.subRegion,
              isActive: i.isActive,
              Region: i?.region,
              displayName: i?.displayName,
              industry: i?.industry,
              aviationType: i?.type,
              airline: i?.airline,
              country: i?.aviationCountry,
              placeOfService: i?.placeOfService,
              type: 'edit_Domain',
              title: 'Edit Tag',
            },
          },
        });
      } else if (this.type === 'emailTags') {
        this.modalService.show(AddEmailTagComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              emailTagId: i._id,
              name: i?.name,
              subRegion: i?.subRegion,
              isActive: i.isActive,
              Region: i?.region,
              type: 'edit_Domain',
              title: 'Edit Email Tag',
            },
          },
        });
      } else if (this.type === 'preApprovedEmails') {
        this.modalService.show(AddEmailComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              emailId: i._id,
              email: i?.email,
              isActive: i.isActive,
              emailTag: i?.emailTag,
              industry: i?.industry,
              type: 'edit_email',
              title: 'Edit Email ',
            },
          },
        });
      } else if (this.type === 'recommandedList') {
        this.modalService.show(AddRecommandedComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              domainId: i._id,
              name: i?.name,
              subRegion: i?.subRegion,
              status: i.status,
              Region: i?.region,
              industry: i?.industry,
              aviationType: i?.type,
              airline: i?.airline,
              country: i?.aviationCountry,
              placeOfService: i?.placeOfService,
              displayName: i?.displayName,
              type: 'edit_Domain',
              title: 'Edit Tag',
            },
          },
        });
      } else if (this.type === 'departmentlist') {
        this.modalService.show(AddDepartmentComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              departmentId: i._id,
              name: i?.name,
              isActive: i.isActive,
              type: 'edit_deptlist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'newslist') {
        this.modalService.show(AddNewsComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              newsId: i._id,
              newsTitle: i?.newsTitle,
              description: i?.description,
              link: i?.link,
              isActive: i.isActive,
              linkType: i.type,
              type: 'edit_newslist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'blogslist') {
        this.modalService.show(AddBlogsComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              blogsId: i._id,
              title: i?.title,
              author: i?.author,
              description: i?.description,
              blogTemplate: i?.blogTemplate,
              link: i?.link,
              isActive: i.isActive,
              linkType: i.type,
              type: 'edit_blogslist',
              thumbnail: i?.thumbnail,
              // title: 'Add Blog',
            },
          },
        });
      } else if (this.type === 'designationlist') {
        this.modalService.show(AddDesignationComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              designationId: i._id,
              name: i?.name,
              country: i?.country,
              isActive: i.isActive,
              type: 'edit_designationlist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'healthcarerolelist') {
        this.modalService.show(AddHealthcareRoleComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              healthcareRoleId: i._id,
              name: i?.name,
              country: i?.country,
              isActive: i.isActive,
              designation: i.designation,
              departments: i.departments,
              type: 'edit_healthcarerolelist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'positionlist') {
        this.modalService.show(AddPositionComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              positionId: i._id,
              name: i?.name,
              country: i?.country,
              isActive: i.isActive,
              type: 'edit_positionlist',
              title: 'Add Position',
            },
          },
        });
      } else if (this.type === 'expertiseList') {
        this.modalService.show(AddExpertiseComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              departmentId: i._id,
              name: i?.name,
              isActive: i.isActive,
              type: 'edit_deptlist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'trainingList') {
        this.modalService.show(AddTrainingComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              trainingId: i._id,
              name: i?.name,
              isActive: i.isActive,
              type: 'edit_deptlist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'translationlist') {
        this.modalService.show(AddTranslationComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              translationId: i._id,
              mainTitle: i?.mainTitle,
              description: i?.description,
              language: i?.language,
              isActive: i.isActive,
              shortCode: i?.shortCode,
              type: 'edit_translationlist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'translationSolutionlist') {
        this.modalService.show(AddSolutionTranslationComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              translationId: i._id,
              mainTitle: i?.mainTitle,
              description: i?.description,
              language: i?.language,
              isActive: i.isActive,
              shortCode: i?.shortCode,
              type: 'edit_solutiontranslationlist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'translationNewslist') {
        this.modalService.show(AddNewsTranslationComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              translationId: i._id,
              mainTitle: i?.mainTitle,
              description: i?.description,
              language: i?.language,
              isActive: i.isActive,
              shortCode: i?.shortCode,
              type: 'edit_newstranslationlist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'translationBloglist') {
        this.modalService.show(AddBlogsTranslationComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              translationId: i._id,
              mainTitle: i?.mainTitle,
              description: i?.description,
              language: i?.language,
              isActive: i.isActive,
              shortCode: i?.shortCode,
              type: 'edit_blogtranslationlist',
              blogBody: i?.blogBody,
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'translationTagslist') {
        this.modalService.show(AddTagsTranslationComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              translationId: i._id,
              mainTitle: i?.mainTitle,
              description: i?.description,
              language: i?.language,
              isActive: i.isActive,
              shortCode: i?.shortCode,
              type: 'edit_tagstranslationlist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'translationInfographiclist') {
        this.modalService.show(AddInfographicTranslationComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              translationId: i._id,
              mainTitle: i?.mainTitle,
              description: i?.description,
              language: i?.language,
              isActive: i.isActive,
              shortCode: i?.shortCode,
              type: 'edit_infographictranslationlist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'mechanicalFailureList') {
        this.modalService.show(AddmechanicalFailureComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              departmentId: i._id,
              name: i?.name,
              isActive: i.isActive,
              type: 'edit_deptlist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'regionlist') {
        this.modalService.show(AddRegionComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              regionId: i._id,
              name: i.name,
              isActive: i.isActive,
              country: i.country,
              industry: i.industry,
              type: 'edit_regionlist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'questionlist') {
        let section = i.sections.find((temp) => temp.order === 1);
        this.modalService.show(AddQuestionnaireComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              questionnaireIds: i._id,
              generalType: i.generalType,
              isActive: i.isActive,
              specificType: i.specificType,
              sectionName: section.name,
              sectionId: section._id,
              industry: i.industry,
              type: 'edit_regionlist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'airlineList') {
        this.modalService.show(AddAirlineComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              regionId: i._id,
              name: i.name,
              isActive: i.isActive,
              country: i.country,
              type: 'edit_regionlist',
              title: 'Add Tag',
            },
          },
        });
      } else if (this.type === 'serviceList') {
        this.modalService.show(AddServiceComponent, {
          ignoreBackdropClick: true,
          keyboard: false,
          class: 'modal-lg modal-dialog-centered',
          initialState: {
            modalData: {
              regionId: i._id,
              name: i.name,
              isActive: i.isActive,
              country: i.country,
              type: 'edit_regionlist',
              title: 'Add Tag',
            },
          },
        });
      }

      //regionlist
    } else if (k.id === 3) {
      if (this.type === 'projectList') {
        this.showErrorService
          .confirmpopToast('warning', 'Do you want to delete??')
          .then((result) => {
            if (result.value === true) {
              const dataObj = {
                ['projectIds']: [i._id],
              };
              this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj);
            }
          });
      } else if (this.type === 'infographicList') {
        this.showErrorService
          .confirmpopToast('warning', 'Do you want to delete??')
          .then((result) => {
            if (result.value === true) {
              const dataObj = {
                infographicId: i._id,
              };
              this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj);
            }
          });
      } else if (this.type === 'replies') {
        this.showErrorService
          .confirmpopToast('warning', 'Do you want to delete??')
          .then((result) => {
            if (result.value === true) {
              const dataObj = {
                [this.params.deleteParams]: i._id,
                solutionId: i.solutionId._id,
              };
              this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj);
            }
          });
      } else if (this.type === 'translationBloglist') {
        this.showErrorService
          .confirmpopToast('warning', 'Do you want to delete??')
          .then((result) => {
            if (result.value === true) {
              const dataObj = {
                [this.params.deleteParams]: [i._id],
                isBlog: true,
              };
              this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj);
            }
          });
      } else if (this.type === 'comments') {
        this.showErrorService
          .confirmpopToast('warning', 'Do you want to delete??')
          .then((result) => {
            if (result.value === true) {
              let service = this.modalService.show(DeleteCommentComponent, {
                ignoreBackdropClick: true,
                keyboard: false,
                class: 'modal-lg modal-dialog-centered',
                initialState: {
                  modalData: {
                    type: 'Report',
                    title: 'Report',
                    data: i,
                  },
                },
              });

              if (service?.onHidden) {
                service.onHidden.subscribe(() => {
                  this.callApiToGetData();
                  // this.callApiToGetData1();
                });
              }
            }
          });
      } else {
        this.showErrorService
          .confirmpopToast('warning', 'Do you want to delete??')
          .then((result) => {
            if (result.value === true) {
              const dataObj = {
                [this.params.deleteParams]: [i._id],
              };
              this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj);
            }
          });
      }
    } else if (k.id === 4) {
      this.showErrorService.showMessageToast('User Feedback', i.deleteFeedback);
    } else if (k.id === 5) {
      this.showErrorService.showMessageToast('Comment', i.comment);
    } else if (k.id === 10) {
      this.showErrorService.showMessageToast('Reply', i.reply);
    } else if (k.id === 6) {
      this.router.navigate([this.URLConstants.VIEWPOSTSOLUTIONS, i._id]);
    } else if (k.id === 7) {
      this.router.navigate([this.URLConstants.VIEWPOSTCOMMENTS, i._id]);
    } else if (k.id === 11) {
      this.router.navigate([this.URLConstants.INCIDENTS_TRANSLATION, i._id]);
      this.localStorageService.setToken('selectedIncidentId', i._id);
    } else if (k.id === 12) {
      this.router.navigate([this.URLConstants.SOLUTION_TRANSLATION, i._id]);
      this.localStorageService.setToken('selectedSolutionId', i._id);
    } else if (k.id === 13) {
      this.router.navigate([this.URLConstants.NEWS_TRANSLATION, i._id]);
      this.localStorageService.setToken('selectedNewsId', i._id);
    } else if (k.id === 14) {
      this.router.navigate([this.URLConstants.TAGS_TRANSLATION, i._id]);
      this.localStorageService.setToken('selectedTagId', i._id);
    } else if (k.id === 15) {
      this.router.navigate([this.URLConstants.POST_TRANSLATION, i._id]);
      this.localStorageService.setToken('selectedIncidentId', i._id);
    } else if (k.id === 16) {
      this.router.navigate([this.URLConstants.INFOGRAPHIC_TRANSLATION, i._id]);
      this.localStorageService.setToken('selectedIncidentId', i._id);
    } else if (k.id === 17) {
      this.router.navigate([this.URLConstants.BLOGS_TRANSLATION, i._id]);
      this.localStorageService.setToken('selectedBlogId', i._id);
    } else if (k.id === 18) {
      this.router.navigate([this.URLConstants.MANAGE_QUESTIONNAIRE, i._id]);
      this.localStorageService.setToken('selectedQuestionnaireId', i._id);
    }
  }
  // SWITCH action
  changeStatus3(d, i) {
    let dataObj = {
      [this.params.statusParams]: [i._id],
    };

    if (d.id === 'isRejected') {
      this.showErrorService
        .confirmpopToast('warning', 'Do you want to delete??')
        .then((result) => {
          if (result.value === true) {
            this.callApiToGetData();
            this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
          } else {
            this.callApiToGetData();
          }
        });
    } else if (d.id === 'isRestored') {
      this.callApiToGetData();
      this.type === 'SolutionDeletelist'
        ? this.CallAPIchangeStatusORDelete(
            this.callAPIConstants.DeleteReportRestoreSolution,
            dataObj
          )
        : this.CallAPIchangeStatusORDelete(
            this.callAPIConstants.DeleteReportRestore,
            dataObj
          );
    }
  }
  changeStatus2(d, i) {
    let dataObj;
    if (this.type === 'reportlist') {
      dataObj = {
        [this.params.statusParams]: [i._id],
        status: d.value,
        type: 'Incident',
      };

      this.callApiToGetData();
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
    } else if (this.type === 'reportsolutionlist') {
      dataObj = {
        [this.params.statusParams]: [i._id],
        status: d.value,
        type: 'Solution',
      };

      this.callApiToGetData();
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
    } else if (this.type === 'recommandedList') {
      dataObj = {
        [this.params.statusParams]: [i._id],
        status: d.value,
      };
      this.callApiToGetData();
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
    }
  }
  statusvalue: any;
  changeStatus1(d, i) {
    let dataObj;
    if (this.type === 'incidentlist') {
      if (d.id === 'isRejected') {
        this.openModal1(i);
      } else {
        dataObj = {
          [this.params.statusParams]: [i._id],
          incidentStatus: d.id,
        };
        this.callApiToGetData();
        this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
      }
    } else if (this.type === 'reachlist') {
      dataObj = {
        [this.params.statusParams]: [i._id],
        responded: d.id,
      };
      this.callApiToGetData();
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
    } else if (this.type === 'postlist') {
      dataObj = {
        [this.params.statusParams]: [i._id],
        incidentStatus: d.id,
      };
      this.callApiToGetData();
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
    } else {
      dataObj = {
        [this.params.statusParams]: [i._id],
        solutionStatus: d.id,
      };
      this.callApiToGetData();
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
    }
  }
  changeStatus(d) {
    if (this.type === 'regionlist') {
      const dataObj = {
        [this.params.statusParams]: [d._id],
        isActive: d.isActive,
      };
      this.callApiToGetData();

      this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
    } else if (
      this.type === 'subRegionlist' ||
      this.type === 'tagListing' ||
      this.type === 'departmentlist' ||
      this.type === 'newslist' ||
      this.type === 'blogslist' ||
      this.type === 'designationlist' ||
      this.type === 'positionlist' ||
      this.type === 'healthcarerolelist' ||
      this.type === 'expertiseList' ||
      this.type === 'trainingList' ||
      this.type === 'mechanicalFailureList' ||
      this.type === 'domainlist' ||
      this.type === 'emailTags' ||
      this.type === 'serviceList' ||
      this.type === 'preApprovedEmails' ||
      this.type === 'airlineList' ||
      this.type === 'translationlist' ||
      this.type === 'translationSolutionlist' ||
      this.type === 'translationNewslist' ||
      this.type === 'translationBloglist' ||
      this.type === 'translationInfographiclist' ||
      this.type === 'translationTagslist' ||
      this.type === 'questionlist'
    ) {
      const dataObj = {
        [this.params.statusParams]: [d._id],
        isActive: d.isActive,
        isBlog: false,
      };
      if (this.type === 'translationBloglist') {
        dataObj.isBlog = true;
      }
      this.callApiToGetData();
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
    } else if (this.type === 'userlist') {
      let isRejected: Boolean;
      if (d.isActive == 'reject') {
        isRejected = true;
        d.isActive = false;
      } else {
        isRejected = false;
      }
      const dataObj = {
        [this.params.statusParams]: [d._id],
        isActive: d.isActive,
        isRejected,
      };
      this.callApiToGetData();

      this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
    } else {
      const dataObj = {
        [this.params.statusParams]: [d._id],
        status: d.status,
      };
      this.callApiToGetData();

      this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
    }
  }
  openModal(type, event?) {
    if (this.type === 'countriesListing') {
      this.modalService.show(CommonModalComponent, {
        initialState: { type: this.type },
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
      });
    } else if (this.type === 'currencyListing') {
      this.modalService.show(CommonModalComponent, {
        initialState: { type: this.type },
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
      });
    } else if (this.type === 'timezoneListing') {
      this.modalService.show(CommonModalComponent, {
        initialState: { type: this.type },
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
      });
    } else if (this.type === 'blogCategoryList') {
      this.modalService.show(AddblogComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'Add_blog_category',
            title: 'Add Blog Category',
          },
        },
      });
    } else if (this.type === 'tagListing') {
      this.modalService.show(AddTagComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_taglist',
            title: 'Add Tag',
          },
        },
      });
    } else if (this.type === 'departmentlist') {
      this.modalService.show(AddDepartmentComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Department',
          },
        },
      });
    } else if (this.type === 'newslist') {
      this.modalService.show(AddNewsComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add News',
          },
        },
      });
    } else if (this.type === 'blogslist') {
      this.modalService.show(AddBlogsComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Blogs',
          },
        },
      });
    } else if (this.type === 'designationlist') {
      this.modalService.show(AddDesignationComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Designation',
          },
        },
      });
    } else if (this.type === 'healthcarerolelist') {
      this.modalService.show(AddHealthcareRoleComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Role',
          },
        },
      });
    } else if (this.type === 'positionlist') {
      this.modalService.show(AddPositionComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Position',
          },
        },
      });
    } else if (this.type === 'expertiseList') {
      this.modalService.show(AddExpertiseComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Expertise',
          },
        },
      });
    } else if (this.type === 'trainingList') {
      this.modalService.show(AddTrainingComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Training',
          },
        },
      });
    } else if (this.type === 'translationlist') {
      this.modalService.show(AddTranslationComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Translation',
          },
        },
      });
    } else if (this.type === 'translationSolutionlist') {
      this.modalService.show(AddSolutionTranslationComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Translation',
          },
        },
      });
    } else if (this.type === 'translationNewslist') {
      this.modalService.show(AddNewsTranslationComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Translation',
          },
        },
      });
    } else if (this.type === 'translationBloglist') {
      this.modalService.show(AddBlogsTranslationComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Translation',
          },
        },
      });
    } else if (this.type === 'translationTagslist') {
      this.modalService.show(AddTagsTranslationComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Translation',
          },
        },
      });
    } else if (this.type === 'translationInfographiclist') {
      this.modalService.show(AddInfographicTranslationComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Translation',
          },
        },
      });
    } else if (this.type === 'mechanicalFailureList') {
      this.modalService.show(AddmechanicalFailureComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add MechanicalFailure ',
          },
        },
      });
    } else if (this.type === 'serviceList') {
      this.modalService.show(AddServiceComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Expertise',
          },
        },
      });
    } else if (this.type === 'regionlist') {
      this.modalService.show(AddRegionComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Region',
          },
        },
      });
    } else if (
      this.type === 'questionlist' &&
      !this.conditions.showDuplicateBtn
    ) {
      this.modalService.show(AddQuestionnaireComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Region',
          },
        },
      });
    } else if (
      this.type === 'questionlist' &&
      this.conditions.showDuplicateBtn
    ) {
      this.modalService.show(DuplicateQuestionnaireComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Duplicate questionnaire',
          },
        },
      });
    } else if (this.type === 'airlineList') {
      this.modalService.show(AddAirlineComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Region',
          },
        },
      });
    } else if (this.type === 'domainlist') {
      this.modalService.show(AddDomainComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Domain',
          },
        },
      });
    } else if (this.type === 'subRegionlist') {
      this.modalService.show(AddsubRegionComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add SubRegion',
          },
        },
      });
    } else if (this.type === 'reportlist') {
      this.modalService.show(ReportComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'Report',
            title: 'Report',
            data: event.reportingDetails,
          },
        },
      });
    } else if (this.type === 'reportsolutionlist') {
      this.modalService.show(ReportSolutionComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'Report',
            title: 'Report',
            data: event.reportingDetails,
          },
        },
      });
    } else if (this.type === 'recommandedList') {
      this.modalService.show(AddRecommandedComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'Recommanded',
            title: 'RecomandedAdd',
            data: 'event.reportingDetails',
          },
        },
      });
    } else if (this.type === 'emailTags') {
      this.modalService.show(AddEmailTagComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Email Tag',
          },
        },
      });
    } else if (this.type === 'preApprovedEmails') {
      this.modalService.show(AddEmailComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Email',
          },
        },
      });
    } else if (this.type === 'qrCampaign') {
      this.modalService.show(AddQrComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Email',
          },
        },
      });
    } else if (this.type === 'postCommentslist') {
      this.modalService.show(AddCommentComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Comment',
            postId: this.postId,
          },
        },
      });
    } else if (this.type === 'postSolutionlist') {
      this.modalService.show(AddSolutionComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'add_list',
            title: 'Add Solution',
            postId: this.postId,
          },
        },
      });
    }
  }
  openModal1(event?) {
    if (event.status === 'isRejected') {
      this.modalService.show(RemoveReportComponent, {
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          modalData: {
            type: 'Report',
            title: 'Report',
            data: event,
          },
        },
      });
    }
  }
  /***************************************************************/

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.colSettingCols,
      event.previousIndex,
      event.currentIndex
    );
  }
  /***************************************************************
    @Purpose :  SELECT ALL USERS IN TABLE
  /***************************************************************/
  selectAllUser(i) {
    if (i.checkbox === true) {
      this.conditions.showApplyStatus = true;
      //this.conditions.showApplyStatus=true;
      this.tableData.data.forEach((element) => {
        element.checkbox = true;
        if (this.selectedUserList.length) {
          const x = this.selectedUserList.findIndex((o) => o === element._id);
          if (x === -1) {
            this.selectedUserList.push(element._id);
          }
        } else {
          this.selectedUserList.push(element._id);
        }
      });
    } else {
      this.deselectMultipleUser();
    }
  }
  /***************************************************************/

  /***************************************************************
    @Purpose :  Common Function
  /***************************************************************/
  deselectMultipleUser() {
    this.tableConfig.cols.forEach((element) => {
      element.checkbox = false;
    });
    this.tableData.data.forEach((element) => {
      element.checkbox = false;
      const x = this.selectedUserList.findIndex((o) => o === element._id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    });
  }

  modifyFilterArr(fieldName, event) {
    const index = this.filter.findIndex((o) =>
      Object.keys(o).includes(fieldName)
    );
    if (index > -1) {
      this.filter[index][fieldName] = event;
    } else {
      this.filter.push({ [fieldName]: event });
    }
  }
  /***************************************************************/

  /***************************************************************
    @Purpose :  filter
  /***************************************************************/
  statusFilterlist: any[] = [];
  statusFilterlist1: any;
  public filter = [];
  public disableApplyFilter = true;
  public disableResetFilter = true;

  toggleFilterColumn() {
    this.filterColumnOption = !this.filterColumnOption;
    this.columnVisibilityOptions = false;
  }
  getSatutsFilterlistData(colName) {
    if (this.type === 'userlist') {
      if (colName === 'isActive') {
        this.statusFilterlist = ['Active', 'Inactive'];
      }
    } else {
      if (colName === 'status') {
        this.statusFilterlist = ['Active', 'Inactive'];
      }
    }
  }

  getSatutsFilterlistData1(colName) {
    if (colName === 'isActive') {
      this.statusFilterlist1 = [
        { name: 'Active', value: true },
        { name: 'Inactive', value: false },
      ];
    }
  }

  selectFilterStatus(fieldName, event) {
    const index = this.tableConfig.cols.findIndex((o) => {
      if (o.value) {
        return o.value.length;
      }
    });
    index > -1
      ? (this.disableApplyFilter = false)
      : (this.disableApplyFilter = true);
    if (fieldName === 'status') {
      // tslint:disable-next-line: triple-equals
      if (event == 'Active') {
        this.modifyFilterArr(fieldName, [true]);
        // tslint:disable-next-line: triple-equals
      } else if (event == 'Inactive') {
        this.modifyFilterArr(fieldName, [false]);
        // tslint:disable-next-line: triple-equals
      } else if (event == '') {
        this.modifyFilterArr(fieldName, []);
      } else {
        this.modifyFilterArr(fieldName, [true, false]);
      }
    } else if (fieldName === 'isActive') {
      if (event[0].name == 'Active') {
        this.modifyFilterArr(fieldName, [true]);
        // tslint:disable-next-line: triple-equals
      } else if (event[0].name == 'isActive') {
        this.modifyFilterArr(fieldName, [false]);
        // tslint:disable-next-line: triple-equals
      }
    } else {
      this.modifyFilterArr(fieldName, event);
    }
  }
  resetFilter() {
    this.disableResetFilter = true;
    this.filter = [];
    this.tableConfig.cols.forEach((element) => {
      element.value = [];
    });
    this.APIparameters.filter = [];
    //this.formDateRangepicker = [];
    this.callApiToGetData();

    this.status = !this.status;
  }
  getSelectedFilterValueFromTyeAhead(data) {
    const index1 = this.tableConfig.cols.findIndex((o) => {
      if (o.value) {
        return o.value.length;
      }
    });
    index1 > -1
      ? (this.disableApplyFilter = false)
      : (this.disableApplyFilter = true);
    let index;

    if (this.type === 'subRegionlist') {
      index = this.filter.findIndex((o) =>
        Object.keys(o).includes(data.fieldName)
      );
    } else {
      index = this.filter.findIndex((o) =>
        Object.keys(o).includes(data.fieldName)
      );
    }

    if (index > -1) {
      this.filter[index][data.fieldName] = data.value;
    } else {
      this.filter.push({ [data.fieldName]: data.value });
    }
  }

  applyFilter() {
    if (this.filter.length) {
      this.disableResetFilter = false;
      this.APIparameters.filter = this.filter;
      this.callApiToGetData();
    }
    this.status = !this.status;
  }

  /***************************************************************/

  /***************************************************************
    @Purpose : SAVE and DELETE FILTER
  /***************************************************************/

  openSaveFilterModal(saveFilters: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(saveFilters, {
      class: 'modal-dialog-centered',
    });
  }

  public dataOfSaveFilter: any;
  CallAPIgetSavedFilter() {
    if (this.getFilter) {
      this.commonService
        .callApi(this.getFilter, '', 'get', false, false)
        .then((success) => {
          if (success.status === 1) {
            this.dataOfSaveFilter = success.data;
          }
        });
    }
  }
  CallAPIgetDownloadTemplates() {
    if (this.getDowloadTemplates) {
      this.commonService
        .callApi(
          `${this.getDowloadTemplates}/${this.tableSetupData.saveColumnKey}`,
          '',
          'get',
          false,
          false
        )
        .then((success) => {
          if (success.status === 1) {
            this.downloadTemplatesArr = success.data;
          }
        });
    }
  }

  showSaveFilter(i) {
    this.disableResetFilter = false;
    this.APIparameters.filter = i.filter;
    this.callApiToGetData();
  }

  CallAPIdeleteSaveFilter(i) {
    this.disableResetFilter = true;
    this.commonService
      .callApi(this.deleteFilter + i._id, '', 'delete', false, false)
      .then((success) => {
        if (success.status && success.status === 1) {
          this.showErrorService.popToast('success', success.message);
        } else {
          this.showErrorService.popToast('error', success.message);
        }
        this.callApiToGetData();
      });
  }
  /****************************************************************************
  // To get search list
  /****************************************************************************/
  searchflag: boolean = false;
  searchData(event) {
    this.searchflag = true;
    delete this.tableData.data;
    delete this.total;
    this.type = this.type;
    let dataObj = {
      page: 1,
      pagesize: 20,
      filter: {
        searchText: event.target.value,
      },
    };
    if (
      this.type === 'userlist' ||
      this.type === 'adminUserlist' ||
      this.type === 'regionlist' ||
      this.type === 'subRegionlist' ||
      this.type === 'domainlist' ||
      this.type === 'departmentlist' ||
      this.type === 'newslist' ||
      this.type === 'blogslist' ||
      this.type === 'designationlist' ||
      this.type === 'positionlist' ||
      this.type === 'healthcarerolelist' ||
      this.type === 'expertiseList' ||
      this.type === 'trainingList' ||
      this.type === 'mechanicalFailureList' ||
      this.type === 'airlineList' ||
      this.type === 'serviceList' ||
      this.type === 'tagListing' ||
      this.type === 'solutionlist' ||
      this.type === 'recommandedList' ||
      this.type === 'emailTags' ||
      this.type === 'preApprovedEmails' ||
      this.type === 'comments'
    ) {
      this.APIparameters['page'] = 1;
      this.APIparameters['pagesize'] = 20;
      this.APIparameters.filter = {
        searchText: event.target.value,
      };
      this.callApiToGetData1();
    } else if (this.type === 'contentlist') {
      this.APIparameters['page'] = 1;
      this.APIparameters['pagesize'] = 20;
      this.APIparameters.filter = {
        pageId: event.target.value,
      };
      this.callApiToGetData1();
    } else if (this.type === 'incidentlist') {
      console.log('in');
      this.APIparameters['page'] = 1;
      this.APIparameters['pagesize'] = 20;
      this.APIparameters.filter = {
        searchText: event.target.value,
        // industry: [this.tableSetupData.industry],
      };
      this.callApiToGetData1();
    } else {
      this.APIparameters.page = 1;
      if (event.target && event.target.value) {
        this.APIparameters.filter = {
          searchText: event.target.value,
        };
      } else {
        delete this.APIparameters.filter.searchText;
      }
      this.callApiToGetData();
    }
  }
  /***************************************************************/
  /***************************************************************
  @Purpose : To Get Dynamic searchValues.
  /***************************************************************/
  searchValue(data) {
    this.APIparameters.searchText = data;
    this.callApiToGetData();
  }

  // For performing selected action on the list
  actionSelected(action) {
    // DELETE
    if (action === 'Delete') {
      this.showErrorService
        .confirmpopToast('warning', 'Do you want to delete??')
        .then((result) => {
          if (result.value === true) {
            const data = {
              userIds: this.selectedUserList,
            };
            this.CallAPIchangeStatusORDelete(this.DeleteUser, data);
          } else {
            this.deselectMultipleUser();
          }
        });
    }
    // DELETE
    // STATUS
    if (action === 'Inactive' || action === 'Active') {
      let data;
      action === 'Inactive'
        ? (data = { userIds: this.selectedUserList, status: false })
        : (data = { userIds: this.selectedUserList, status: true });
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, data);
      this.deselectMultipleUser();
    }
    // STATUS
  }

  // To get the selected dateType info
  dateSelected(e, index) {
    if (e.name !== 'custom') {
      var date = new Date();
      let presentDate =
        e.name === 'Previous Month' ||
        e.name === 'Previous Quarter' ||
        e.name === 'Previous Year'
          ? 'month/01/year'
          : date.toLocaleDateString();
      let value = {
        calendarSpecificCount: e.value,
        calendarSpecificType: e.type,
        presentDate: presentDate,
      };
      this.filteredArr[index]['value'] = value;
    }
  }

  // when getting startDate and endDate
  rangeSelected(e, index) {
    if (e) {
      let value = {
        startDate: e[0].toLocaleDateString(),
        endDate: e[1].toLocaleDateString(),
      };
      this.filteredArr[index]['value'] = value;
    }
  }

  // To clear the filter
  clearFilter() {
    this.filteredArr = [];
    this.addTask();
    this.filterColor = '#4184EF';
    this.callApiToGetData();
    this.filterForm.reset();
    this.options.description1 = null;
    this.submitted = false;
    this.submitted1 = false;
    if (this.status) {
      this.status = !this.status;
    }
  }

  clearFilterOnSaveSearch() {
    this.filteredArr = [];
    this.addTask();
    this.filterColor = '#4184EF';
    this.callApiToGetData();
    // this.filterForm.reset();
    this.options.description1 = null;
    this.submitted = false;
    this.submitted1 = false;
    if (this.status) {
      this.status = !this.status;
    }
  }

  typeArrChanges(item) {
    delete item.selectedDate;
    delete item.value;
  }
  selectOperation(event) {
    if (event.type == 'switch') {
      this.isFilterStatus = true;
      // filter related variables
      this.typeArr = [
        { name: 'Equal', value: 'equal' },
        { name: 'Not Equal', value: 'notEqual' },
      ];
    } else if (event.type == 'date') {
      this.isFilterStatus = false;
      this.typeArr = [
        { name: 'between', value: 'between' },
        { name: 'date', value: 'date' },
      ];
    } else if (event.type == 'text') {
      this.isFilterStatus = false;
      this.typeArr = [
        { name: 'Equal', value: 'equal' },
        { name: 'Not Equal', value: 'notEqual' },
        { name: 'Contains', value: 'contains' },
        { name: "Doesn't Contains", value: 'doesntContains' },
        { name: 'Starts With', value: 'startsWith' },
        { name: 'Finishes With', value: 'finishesWith' },
        { name: 'Is Empty', value: 'isEmpty' },
        { name: 'Is Not Empty', value: 'isNotEmpty' },
      ];
    }
  }

  // To save the filter
  toSaveFilter(form, filterName, color) {
    this.submitted = true;
    this.submitted1 = true;
    if (form && form.valid && filterName) {
      this.APIparameters = {
        key: this.APIparameters.columnKey,
        condition: this.condition,
        description: filterName,
        color: color,
        filter: this.filteredArr,
        filterId: this.filterId,
      };
      this.commonService
        .callApi(this.saveFilter, this.APIparameters, 'post')
        .then((success) => {
          if (success.status && success.status === 1) {
            this.APIparameters = {
              page: 1,
              pagesize: 10,
              columnKey: this.APIparameters.columnKey,
              filter: this.filteredArr,
              condition: this.condition,
            };

            this.callApiToGetData();
            this.clearFilterOnSaveSearch();
            // this.clearFilter();
          } else {
            this.showErrorService.popToast('error', success.message);
          }
        });
    }
  }

  editFilter(e) {
    this.filteredArr = e.filter;
    this.options.description1 = e.description;
    this.filterColor = e.color;
    var hash = this.colors.find((a) => a.name == e.color).hex;
    document.getElementById('filterDropdownColor').style.backgroundColor = hash;
    this.condition = e.condition;
    this.filterId = e._id;
  }

  removeSearch() {
    this.searchflag = false;
    this.tableData.data = [];
    this.total = [];
    if (
      this.type === 'userlist' ||
      this.type === 'adminUserlist' ||
      this.type === 'regionlist' ||
      this.type === 'subRegionlist' ||
      this.type === 'incidentlist' ||
      this.type === 'domainlist' ||
      this.type === 'departmentlist' ||
      this.type === 'newslist' ||
      this.type === 'designationlist' ||
      this.type === 'positionlist' ||
      this.type === 'healthcarerolelist' ||
      this.type === 'expertiseList' ||
      this.type === 'trainingList' ||
      this.type === 'mechanicalFailureList' ||
      this.type === 'airlineList' ||
      this.type === 'serviceList' ||
      this.type === 'tagListing' ||
      this.type === 'contentlist' ||
      this.type === 'solutionlist' ||
      this.type === 'recommandedList'
    ) {
      this.APIparameters.page = 1;
      this.APIparameters.perPage = 10;
      delete this.searchText;
    } else if (
      this.type === 'preApprovedEmails' ||
      this.type === 'emailTags' ||
      this.type === 'tagListing'
    ) {
      this.APIparameters.page = 1;
      this.APIparameters.perPage = 10;
      this.localStorageService.setToken('currentPage', 1);
      this.localStorageService.setToken('itemsPerPage', 10);
      delete this.searchText;
    } else {
      delete this.searchText;
      delete this.APIparameters.searchText;
      delete this.APIparameters.search;
      delete this.APIparameters.filter.searchText;
    }

    setTimeout(() => {
      this.callApiToGetData();
    }, 1000);
  }
  removeSearch1() {
    delete this.searchText;
    delete this.APIparameters.searchText;
    delete this.APIparameters.search;
    delete this.APIparameters.filter.searchText;
    if (this.type === 'reachlist') {
      this.callApiToGetData1();
    } else {
      this.callApiToGetData();
    }
  }
  //new update
  dropdownList = [
    { id: 'Active', value: true },
    { id: 'InActive', value: false },
  ];
  dropdownList8 = [
    { id: 'Active', value: true },
    { id: 'InActive', value: false },
    { id: 'Rejected', value: 'reject' },
  ];
  dropdownList9 = [
    { id: 'Enable', value: true },
    { id: 'Disable', value: false },
  ];
  dropdownList4 = [{ id: 'isRejected', value: 'Delete' }];
  dropdownListRestore = [{ id: 'isRestored', value: 'Restore' }];
  dropdownList3 = [
    { value: 'Keep', id: 'isKept' },
    { value: 'Pending', id: 'isPending' },
  ];
  dropdownList2 = [
    { id: 'Keep', value: 'isKept' },
    { id: 'Remove', value: 'isRemoved' },
    { id: 'Pending', value: 'isPending' },
  ];
  dropdownlist5 = [
    { id: 'isRejected', value: 'Reject' },
    { id: 'isApproved', value: 'Approved' },
  ];
  dropdownlist1 = [
    { id: 'isSubmitted', value: 'Pending' },
    { id: 'isRejected', value: 'Reject' },
    { id: 'isApproved', value: 'Approved' },
  ];

  dropdownlistforpublic = [
    { id: 'isSubmitted', value: 'Pending' },
    { id: 'isRejected', value: 'Reject' },
    { id: 'isApproved', value: 'Approved' },
    { id: 'isApprovedAndPublic', value: 'Post to public & approve' },
  ];

  dropdownlistforpublicadmin = [
    { id: 'isApproved', value: 'Make private' },
    { id: 'isApprovedAndPublic', value: 'Post to public' },
  ];

  dropdownlist7 = [
    { id: 'Approved', value: 'Approved' },
    { id: 'Rejected', value: 'Rejected' },
    { id: 'Pending', value: 'Pending' },
  ];

  dropdownlistreachus = [
    { id: true, value: 'Responded' },
    { id: false, value: 'Not responded' },
  ];

  onFileDropped(files: FileList) {
    this.handleFileInput(files);
  }

  handleFileInput(files: FileList) {
    const file = files.item(0);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvContent = e.target.result as string;
        this.processCSV(csvContent);
      };
      reader.readAsText(file);
    }
  }

  processCSV(csvContent: string) {
    const rows = csvContent.split('\n');
    this.emails = rows
      .map((row) => {
        const cols = row.split(',');
        return cols[0].trim();
      })
      .filter((email) => email.includes('@'));

    this.addUsersFromCSV(this.emails, this.selectedPermissions);
  }

  togglePermission(permission) {
    this.selectedPermissions = this.permissions
      .filter((p) => p.isSelected)
      .map((p) => p.name);
  }

  browseFiles(event) {
    this.handleFileInput(event.target.files);
  }
  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files.length) {
      this.handleFileInput(event.dataTransfer.files);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }
  addUsersFromCSV(emails, permissions) {
    let dataObj = {
      emailIds: emails,
      permissions: permissions,
    };

    this.spinnerService.show();
    this.commonService
      .callApi(this.callAPIConstants.CheoAddCSVUsers, dataObj, 'post')
      .then((success) => {
        let failedItems = success.results.filter((item) => item.status === 0);
        this.failedEmails = failedItems.filter(
          (item) =>
            item.message !== 'An account already exists with e-mail given.'
        );
        if (success.status == 1) {
          this.showErrorService.popToast('success', success.message);
          this.spinnerService.hide();
          this.router.navigate([this.URLConstants.USERS]);
        } else {
          this.showErrorService.popToast('error', success.message);
          this.spinnerService.hide();
          this.router.navigate([this.URLConstants.USERS]);
        }
      });
  }
}
