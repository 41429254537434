import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// Auth Guard
import { CanLoginActivate, CanAuthActivate } from './common/service/auth-guard';

// Component
import { MainComponent } from './modules/main/main.component';
import { LoginComponent } from './public/login/login.component';
import { RegisterComponent } from './public/register/register.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { LoginWithMobileComponent } from './public/login-with-mobile/login-with-mobile.component';
import { LoginWithEmailComponent } from './public/login-with-email/login-with-email.component';
import { SocialLoginComponent } from './public/social-login/social-login.component';
import { EnterOtpComponent } from './public/enter-otp/enter-otp.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { MasterComponent } from './modules/master/master.component';

// plugin
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

// module
import { SharedModule } from './shared/shared.module';
import { SetPasswordComponent } from './public/set-password/set-password.component';

// Service

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    canActivate: [CanLoginActivate],
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'LoginWithMobile',
    canActivate: [CanLoginActivate],
    component: LoginWithMobileComponent,
    pathMatch: 'full',
  },
  {
    path: 'register',
    canActivate: [CanLoginActivate],
    component: RegisterComponent,
    pathMatch: 'full',
  },
  {
    path: 'forgot-password',
    canActivate: [CanLoginActivate],
    component: ForgotPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'reset-password',
    canActivate: [CanLoginActivate],
    component: ResetPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'set-password',
    canActivate: [CanLoginActivate],
    component: SetPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'social-login',
    canActivate: [CanLoginActivate],
    component: SocialLoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'enter-otp/:phNum/:otp',
    canActivate: [CanLoginActivate],
    component: EnterOtpComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    component: MainComponent,
    children: [
      { path: 'master', component: MasterComponent, pathMatch: 'full' },
      {
        path: 'users',
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import('./modules/user-management/users/users.module').then(
            (mod) => mod.UsersModule
          ),
      },
      {
        path: 'admin-users',
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import(
            './modules/user-management/admin-users/admin-users.module'
          ).then((mod) => mod.AdminUsersModule),
      },
      {
        path: 'change-password',
        loadChildren: () =>
          import('./modules/change-password/change-password.module').then(
            (m) => m.ChangePasswordModule
          ),
      },
      {
        path: 'my-profile',
        loadChildren: () =>
          import('./modules/my-profile/my-profile.module').then(
            (m) => m.MyProfileModule
          ),
      },
      {
        path: 'setting',
        loadChildren: () =>
          import('./modules/setting/setting.module').then(
            (m) => m.SettingModule
          ),
      },
      {
        path: 'content',
        loadChildren: () =>
          import('./modules/content-management/content-management.module').then(
            (m) => m.ContentManagementModule
          ),
      },
      {
        path: 'email-template',
        // canActivate: [CanViewListActivate],
        data: { role: 'EmailTemplate' },
        loadChildren: () =>
          import(
            './modules/email-management/email-templates/email-templates.module'
          ).then((m) => m.EmailTemplatesModule),
      },
      {
        path: 'manage-roles',
        loadChildren: () =>
          import('./modules/manage-roles/manage-roles.module').then(
            (m) => m.ManageRolesModule
          ),
      },
      {
        path: 'tag',
        loadChildren: () =>
          import('./modules/tag/tag.module').then((m) => m.TagModule),
      },
      {
        path: 'incident-management',
        loadChildren: () =>
          import(
            './modules/incident-management/incident-management.module'
          ).then((m) => m.IncidentManagementModule),
      },
      {
        path: 'solution-management',
        loadChildren: () =>
          import(
            './modules/solution-management/solution-management.module'
          ).then((m) => m.SolutionManagementModule),
      },
      {
        path: 'report',
        loadChildren: () =>
          import('./modules/report/report.module').then((m) => m.ReportModule),
      },
      {
        path: 'domain-recommendation',
        loadChildren: () =>
          import(
            './modules/domain-recommendation/domain-recommendation.module'
          ).then((m) => m.DomainRecommendationModule),
      },
      {
        path: 'region',
        loadChildren: () =>
          import('./modules/region/region.module').then((m) => m.RegionModule),
      },
      {
        path: 'question-management',
        loadChildren: () =>
          import('./modules/question-management/question.module').then(
            (m) => m.QuestionModule
          ),
      },
      {
        path: 'sub-region',
        loadChildren: () =>
          import('./modules/sub-region/sub-region.module').then(
            (m) => m.SubRegionModule
          ),
      },
      {
        path: 'domain-companies',
        loadChildren: () =>
          import('./modules/domain-companies/domain-companies.module').then(
            (m) => m.DomainCompaniesModule
          ),
      },
      {
        path: 'Dashboards',
        canActivate: [CanAuthActivate],
        loadChildren: () =>
          import('./modules/dashboards/dashboards.module').then(
            (m) => m.DashboardsModule
          ),
      },
      {
        path: 'reachus',
        loadChildren: () =>
          import('./modules/reachus/reachus.module').then(
            (m) => m.ReachusModule
          ),
      },

      {
        path: 'departments',
        loadChildren: () =>
          import('./modules/departments/departments.module').then(
            (m) => m.DepartmentsModule
          ),
      },
      {
        path: 'designations',
        loadChildren: () =>
          import('./modules/designations/designations.module').then(
            (m) => m.DesignationsModule
          ),
      },
      {
        path: 'positions',
        loadChildren: () =>
          import('./modules/positions/positions.module').then(
            (m) => m.PositionsModule
          ),
      },
      {
        path: 'healthcareroles',
        loadChildren: () =>
          import('./modules/healthcareroles/healthcareroles.module').then(
            (m) => m.HealthcareRolesModule
          ),
      },
      {
        path: 'expertise',
        loadChildren: () =>
          import('./modules/expertise/expertise.module').then(
            (m) => m.ExpertiseModule
          ),
      },
      {
        path: 'training',
        loadChildren: () =>
          import('./modules/training/training.module').then(
            (m) => m.TrainingModule
          ),
      },
      {
        path: 'mechanicalFailure',
        loadChildren: () =>
          import('./modules/mechanical-failure/mechanical-failure.module').then(
            (m) => m.MechanicalFailureModule
          ),
      },
      {
        path: 'airline',
        loadChildren: () =>
          import('./modules/airline/airline.module').then(
            (m) => m.AirlineModule
          ),
      },
      {
        path: 'place-of-service',
        loadChildren: () =>
          import('./modules/place-of-service/service.module').then(
            (m) => m.ServiceModule
          ),
      },
      {
        path: 'news',
        loadChildren: () =>
          import('./modules/news/news.module').then((m) => m.NewsModule),
      },
      {
        path: 'blogs',
        loadChildren: () =>
          import('./modules/blogs/blogs.module').then((m) => m.BlogsModule),
      },
      {
        path: 'post-management',
        loadChildren: () =>
          import('./modules/post-management/post-management.module').then(
            (m) => m.PostManagementModule
          ),
      },
      {
        path: 'pre-approved-emails',
        loadChildren: () =>
          import(
            './modules/pre-approved-emails/pre-approved-emails.module'
          ).then((m) => m.PreApprovedEmailsModule),
      },
      {
        path: 'comment-management',
        loadChildren: () =>
          import('./modules/comment-management/comment-management.module').then(
            (m) => m.CommentManagementModule
          ),
      },
      {
        path: 'qr-management',
        loadChildren: () =>
          import(
            './modules/qr-campaign-management/qr-campaign-management.module'
          ).then((m) => m.QrCampaignManagementModule),
      },
      {
        path: 'reply-management',
        loadChildren: () =>
          import('./modules/reply-management/reply-management.module').then(
            (m) => m.ReplyManagementModule
          ),
      },
      { path: '**', redirectTo: 'Dashboards', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoginWithMobileComponent,
    LoginWithEmailComponent,
    SocialLoginComponent,
    EnterOtpComponent,
    MainComponent,
    MasterComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    NgOtpInputModule,
    NgxIntlTelInputModule,
    RouterModule.forRoot(routes, { useHash: false }),
  ],
  exports: [RouterModule],
  providers: [CanLoginActivate],
})
export class AppRoutingModule {}
